import { useContext }                  from "react";
import { useMemo }                     from "react";
import { useQuery }                    from "@apollo/client";
import { gql }                         from "@apollo/client";
import { useField }                    from "@relcu/form";
import { GetCountyLoanLimitVariables } from "../PricingView/LoanCriteria/__types__/GetCountyLoanLimit";
import { GetCountyLoanLimit }          from "../PricingView/LoanCriteria/__types__/GetCountyLoanLimit";
import { Proposal }                    from "./Proposal";

export function useBetaLoanLimit() {
  const context = useContext(Proposal.Context);
  const { input: { value: type } } = useField("productType", { subscription: { value: true } });
  const { propertyCountry, propertyState } = context;
  const where = useMemo(() => {
    const w: any = {};
    if (propertyCountry) {
      w.countyName = {
        matchesRegex: propertyCountry,
        options: "i"
      };
    }

    if (propertyState) {
      w.stateCode = {
        equalTo: propertyState
      };
    }

    return w;
  }, [propertyState, propertyCountry]);

  const { input: { value: propertyType } } = useField("property.type", { subscription: { value: true } });
  const { data: { countyLoanLimits } = {} } = useQuery<GetCountyLoanLimit, GetCountyLoanLimitVariables>(GET_COUNTY_LOAN_LIMITS, {
    variables: { where }
  });
  if (type === "va") {
    return Infinity;
  }
  const loanLimits = countyLoanLimits?.edges[ 0 ]?.node;
  const loanLimit = type === "conventional" ? loanLimits?.conventionalLoanLimit : loanLimits?.fhaLoanLimit;
  const limitMapping = {
    "1_unit": "oneUnitLimit",
    "2_unit": "twoUnitLimit",
    "3_unit": "threeUnitLimit",
    "4_unit": "fourUnitLimit"
  };
  return limitMapping[ propertyType ] ? loanLimit?.[ limitMapping[ propertyType ] ] : loanLimit?.oneUnitLimit;
}

export const GET_COUNTY_LOAN_LIMITS = gql`
  query GetBetaCountyLoanLimit($where:CountyLoanLimitWhereInput!){
    countyLoanLimits(where:$where){
      edges{
        node{
          fhaLoanLimit{
            oneUnitLimit
            twoUnitLimit
            threeUnitLimit
            fourUnitLimit
          }
          conventionalLoanLimit{
            oneUnitLimit
            twoUnitLimit
            threeUnitLimit
            fourUnitLimit
          }
        }
      }
    }
  }
`;
