import React                                    from "react";
import { FC }                                   from "react";
import { useContext }                           from "react";
import { FieldCell }                            from "@relcu/rc";
import { useSource }                            from "@relcu/ui";
import { FormWhere }                            from "@relcu/ui";
import { useField }                             from "@relcu/final-form";
import { format }                               from "../../../../../../utils/helpers";
import { Proposal }                             from "../../Proposal";
import { WHEN_DOWN_PAYMENT_LOWER_THEN_MINIMAL } from "../offer.conditions";
import { WHEN_IS_PURCHASE }                     from "../offer.conditions";
import { NullValueCell }                        from "./NullValueCell";

export const DownPaymentCell: FC<any> = React.memo(function DownPaymentCell() {
  const { $settings: { pricing: settings } } = useSource();
  const context = useContext(Proposal.Context);
  const { input: { value: productType } } = useField("productType");
  const { input: { value: propertyValue } } = useField("propertyValue");
  const minPercent = settings[ productType ]?.purchase?.minDownPaymentPercent;
  const minDownPayment = propertyValue * minPercent / 100;

  return <FormWhere conditions={WHEN_IS_PURCHASE} source={context} nullValue={<NullValueCell/>}>
    <FormWhere
      conditions={WHEN_DOWN_PAYMENT_LOWER_THEN_MINIMAL}
      source={{
        $minPercent: minPercent,
        $minDownPayment: minDownPayment
      }}>
      {
        ({ apply }) => (
          <FieldCell.Number
            status={apply ? "warning" : null}
            name={"downPayment"}
            notNullable
            icon={"attach_money"}
            helperText={apply ? `Must be at least $${format(minDownPayment, 2)}` : "Value can not be equal to zero or empty"}
            precision={2}/>
        )
      }
    </FormWhere>
  </FormWhere>;
});
