import React                              from "react";
import { FC }                             from "react";
import { useContext }                     from "react";
import { useField }                       from "@relcu/final-form";
import { FieldCell }                      from "@relcu/rc";
import { useSource }                      from "@relcu/ui";
import { FormWhere }                      from "@relcu/ui";
import { Proposal }                       from "../../Proposal";
import { WHEN_FICO1_IS_GREATER_THEN_500 } from "../offer.conditions";
import { WHEN_FICO_IS_GREATER_THEN_500 }  from "../offer.conditions";

export const ScoreCell: FC<{ name: string }> = React.memo(function ScoreCell(props) {
  const { $settings: { pricing: settings } } = useSource();
  const context = useContext(Proposal.Context);
  const { input: productTypeInput } = useField("productType");
  const purpose = context.loanPurpose === "purchase" ? "purchase" : "refinance";
  const minScore = settings[ productTypeInput.value ]?.[ purpose ].minCreditScore;

  return <FormWhere
    conditions={props.name == "fico" ? WHEN_FICO_IS_GREATER_THEN_500 : WHEN_FICO1_IS_GREATER_THEN_500}
    source={{
      $minScore: minScore
    }}>
    {
      ({ apply }) => (
        <FieldCell.Number
          status={apply ? "warning" : null}
          name={props.name}
          precision={0}
          required={props.name === "fico"}
          helperText={apply ? `Minimum score is ${minScore}` : "Value can not be equal to zero or empty"}/>
      )
    }
  </FormWhere>;
});
