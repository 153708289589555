import React                   from "react";
import { FC }                  from "react";
import { useEffect }           from "react";
import { useField }            from "@relcu/form";
import { useSource }           from "@relcu/ui";
import { SearchableSelect }    from "@relcu/ui";
import { usePointerListField } from "../../Field/PointerListField/usePointerListField";

interface SignatureUserSelectProps {
  name: string;
  label?: string;
}

export const SignatureUserSelect: FC<SignatureUserSelectProps> = React.memo(function SignatureUserSelect(props) {
  const { input } = useField(props.name);
  const { $viewer } = useSource();

  const { options: users, setQ, q, setSelectedIds } = usePointerListField({
    targetClass: "User",
    ids: [$viewer.objectId],
    fields: [
      "objectId",
      "objectName",
      "objectIcon",
      "nmlsId",
      "firstName",
      "lastName",
      "reviewUrl",
      "applyUrl",
      "calendar",
      "username",
      "positionName",
      {
        "phoneLines": [
          {
            "edges": [
              {
                "node": [
                  {
                    "ACL": [
                      {
                        "users": [
                          "read",
                          "write",
                          "userId"
                        ]
                      },
                      {
                        "roles": [
                          "read",
                          "write",
                          "roleName"
                        ]
                      },
                      {
                        "teams": [
                          "read",
                          "write",
                          "teamName"
                        ]
                      },
                      {
                        "public": [
                          "read",
                          "write"
                        ]
                      }
                    ]
                  },
                  "callRouteToUser",
                  "callRouteToUserRules",
                  "common",
                  "createdAt",
                  "fax",
                  "forwardToNumber",
                  "holdMediaRepeat",
                  "holdMediaUrl",
                  "id",
                  "mms",
                  "name",
                  "number",
                  "objectId",
                  "objectName",
                  "resolution",
                  "sms",
                  "updatedAt",
                  "voice",
                  "voicemailMediaUrl"
                ]
              }
            ]
          }
        ]
      }
    ]
  });

  useEffect(() => {
    setSelectedIds(input.value ? [input.value[ "objectId" ]] : []);
  }, [input.value]);

  return <SearchableSelect
    flex={1}
    onChange={(value) => input.onChange(value)}
    value={input.value}
    optionKey={"objectId"}
    optionLabel={"objectName"}
    options={users}
    label={props.label}
    searchText={q}
    onType={(e) => setQ(e)}/>;

});
