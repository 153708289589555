import { useCallback }                       from "react";
import { DateTime }                          from "luxon";
import { useApolloClient }                   from "@apollo/client";
import { useMutation }                       from "@apollo/client";
import { useQuery }                          from "@apollo/client";
import { gql }                               from "@apollo/client";
import { useNavigate }                       from "@relcu/react-router";
import { useAlert }                          from "@relcu/ui";
import { deviceVar }                         from "../../../reactiveVars";
import { toFirstLower }                      from "../../../utils/helpers";
import { isOverdue }                         from "../../../utils/helpers";
import { getObjectPath }                     from "../../../utils/layoutUtils";
import { MarkNotificationsAsRead }           from "../../__types__/MarkNotificationsAsRead";
import { MARK_NOTIFICATIONS_AS_READ }        from "../../operations.graphql";
import { useViewerPhoneLines }               from "../../useViewerPhoneLines";
import { GetNotificationLeadScopeVariables } from "./__types__/GetNotificationLeadScope";
import { GetNotificationLeadScope }          from "./__types__/GetNotificationLeadScope";
import { GetViewerId }                       from "./__types__/GetViewerId";
import { NotificationTypes }                 from "./InAppNotification";

export const useNotification = () => {
  const { data: { viewer: { user } } } = useQuery<GetViewerId>(GET_VIEWER_ID, {
    fetchPolicy: "cache-only"
  });
  const [markAsRead] = useMutation<MarkNotificationsAsRead>(MARK_NOTIFICATIONS_AS_READ);
  const phone = deviceVar();
  const navigate = useNavigate();
  const { push, closeAlert } = useAlert();
  const client = useApolloClient();
  const { fromNumbers, hasMicrophoneIssue } = useViewerPhoneLines();
  const getLoanRecord = useCallback((node) => {
    return {
      ...node,
      recordId: node?.data?.milestoneId,
      __typename: node.scope.__typename
    };
  }, []);
  const getLeadRecord = useCallback((node) => {
    return {
      ...node,
      leadId: node?.data?.id,
      __typename: node.scope.__typename
    };
  }, []);
  const getMessageRecord = useCallback((node) => {
    const record = node.record;
    // const target = node.type === "sms" ? record?.participants?.find(p => p.type === "sender").party : record?.parties?.find(p => p.type === "sender").party;
    // const __typename = target?.__typename;
    // const objectId = target?.objectId;
    const scope = node.scope || user;
    // const objectName = scope.objectName;

    let recordUnread;
    if (typeof record.unread === "undefined") {
      if (node.type === "bulk_email") {
        recordUnread = node.lastSender?.unread;
      } else {
        const participants = node.type === "sms" ? record?.participants : record?.parties;
        recordUnread = participants?.find(p => p.party?.objectId === user.objectId)?.unread;
      }
    } else {
      recordUnread = record.unread;
    }
    return {
      ...node,
      targetObjectId: scope.objectId,
      targetObjectName: scope.objectName,
      recordUnread,
      recordId: node.type == "email" ? record?.conversation?.objectId : record?.objectId,
      __typename: scope.__typename
    };
  }, [user]);
  const getCallRecord = useCallback((node) => {
    const record = node.record;
    // const target = record.calls.find(p => p.type === "caller").party;
    // const objectId = target?.objectId;
    const scope = node.scope;
    const objectName = scope?.objectName || record.objectName;//getContactName({ ...record, objectId, objectName: target?.objectName }, scope);
    return {
      ...node,
      targetObjectName: objectName,
      targetScope: scope,
      recordUnread: record.unread,
      recordId: record?.objectId
    };
  }, []);
  const getReminderRecord = useCallback((node) => {
    let remindDate = "";
    const date = DateTime.fromISO(node.record.dueDate);
    const now = DateTime.now();
    const time = date.toLocaleString(DateTime.TIME_SIMPLE);
    if (date.day === now.day) {
      remindDate = `Today at ${time}`;
    } else if (date.day === now.day - 1) {
      remindDate = `Yesterday at ${time}`;
    } else {
      remindDate = date.toFormat(" d MMM, yyyy");
    }

    return {
      ...node,
      recordId: node.record?.objectId,
      remindDate,
      recordUnread: true,
      isOverdue: isOverdue(node.record),
      __typename: node.scope.__typename
    };
  }, []);

  const messageNavigate = (node) => {
    let number;
    const record = node.record;
    let target = node.scope || record;

    const isEmail = node.type == "email";
    if (isEmail) {
      return navigate(`${getObjectPath(target)}/${node.type}s/${node.recordId}/${node.record.objectId}`);
    }

    const queryString = `/${node.recordId}`;
    if (node.type == "bulk_email") {
      return navigate(`/user/${node.user?.objectId || user.objectId}/emails/${node.recordId}`);
    }
    if (node.type == "bulk_sms") {
      return navigate(`/user/${node.user?.objectId || user.objectId}/sms/${node.recordId}`);
    }

    return navigate(`${getObjectPath(target)}/${node.type}${queryString}`,
      { state: { to: { value: number?.number, smsOptOut: number?.smsOptOut } } });
  };

  function isOpen() {
    return !phone?.active;
  }

  const leadNavigate = (node) => {
    const { scope, unread, record } = node;
    if (unread && isOpen() && fromNumbers[ 0 ] && record.autoDial && !record.deleted && !record.disabled) {
      client.query<GetNotificationLeadScope, GetNotificationLeadScopeVariables>({
        query: GET_NOTIFICATION_LEAD_SCOPE,
        variables: { id: scope.id }
      }).then(({ data: { lead: scope } }) => {
        const primaryMember = scope.members.find((member) => member.isPrimary);
        primaryMember.contact.phones[ 0 ]?.number && !hasMicrophoneIssue && phone?.call({
          from: fromNumbers[ 0 ].value,
          to: primaryMember.contact.phones[ 0 ]?.number,
          contactId: primaryMember.contact?.objectId,
          contactName: primaryMember.contact?.objectName,
          scopeId: scope.objectId,
          scopeName: scope.objectName,
          scopeClassName: scope.__typename
        });
      });

    }
    return navigate(`${getObjectPath(scope)}/details`);
  };
  const callNavigate = (node) => {
    let target = node.scope;
    return navigate(`${getObjectPath(target)}/calls/${node.recordId}`);
  };
  const markNodeAsRead = (node) => {
    if (node.unread && !node.recordUnread) {
      markAsRead({
        variables: {
          ids: [node.objectId]
        }
      }).catch((e) => {
        console.error(e);
      });
    }
  };
  const onNavigate = (node, id = null) => {
    markNodeAsRead(node);
    const { scope, type, recordId } = node;
    switch (type) {
      case NotificationTypes.MILESTONE_COMMENT:
        navigate(`${getObjectPath(scope)}/loan?lid=${node?.data?.loanId}&from=${recordId}`);
        break;
      case NotificationTypes.SMS:
        messageNavigate(node);
        break;
      case NotificationTypes.CALL:
        callNavigate(node);
        break;
      case NotificationTypes.EMAIL:
        messageNavigate(node);
        break;
      case NotificationTypes.REMINDER:
        navigate(`${getObjectPath(scope)}/reminders`);
        break;
      case NotificationTypes.LEAD_ASSIGNMENT:
        navigate(`${getObjectPath(scope)}`);
        break;
      case NotificationTypes.BULK_EMAIL:
        messageNavigate(node);
        break;
      case NotificationTypes.BULK_SMS:
        messageNavigate(node);
        break;
      case NotificationTypes.DISTRIBUTED_LEAD:
        leadNavigate(node);
        break;
      default:
        return;
    }
    id && closeAlert(id);
  };
  return {
    push,
    navigate,
    getRecord(node) {
      switch (node.type) {
        case NotificationTypes.MILESTONE_COMMENT:
          return getLoanRecord(node);
        case NotificationTypes.SMS:
          return getMessageRecord(node);
        case NotificationTypes.CALL:
          return getCallRecord(node);
        case NotificationTypes.EMAIL:
        case NotificationTypes.BULK_EMAIL:
          return getMessageRecord(node);
        case NotificationTypes.BULK_SMS:
          return getMessageRecord(node);
        case NotificationTypes.REMINDER:
          return getReminderRecord(node);
        case NotificationTypes.DISTRIBUTED_LEAD:
          return getLeadRecord(node);
        case NotificationTypes.LEAD_ASSIGNMENT:
          return getLeadRecord(node);
        default:
          return;
      }

    },
    onNavigate
  };
};

const GET_VIEWER_ID = gql`
  query GetViewerId{
    viewer {
      user {
        id
        objectId
      }
    }
  }
`;
const GET_NOTIFICATION_LEAD_SCOPE = gql`
  query GetNotificationLeadScope($id:ID!) {
    lead(id:$id){
      __typename
      ...on Node {
        id
      }
      ...on Document {
        objectId
        objectName
      }
      ... on Lead{
        members {
          type
          isPrimary
          contact {
            id
            objectId
            objectName
            objectIcon
            firstName
            lastName
            middleName
            phones {
              number
              objectName
              type
              carrier
              nationalFormat
              sms
              smsCountryCode
              callOptOut
              smsOptOut
              default
            }
          }
        }
        timezone
      }
    }
  }
`;
