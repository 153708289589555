import { gql }                              from "@apollo/client";
import { useMutation }                      from "@apollo/client";
import { Button }                           from "@relcu/rc";
import { useNavigate }                      from "@relcu/react-router";
import { useLazyCondition }                 from "@relcu/ui";
import { useSource }                        from "@relcu/ui";
import { omit }                             from "@relcu/ui";
import { useCallback }                      from "react";
import { useMemo }                          from "react";
import { useContext }                       from "react";
import React                                from "react";
import { FC }                               from "react";
import { LoanEstimateOffer }                from "../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE }                    from "../../../../graph/operations.graphql";
import { usePreviewLoanProposal }           from "../../../Modal/ProposalPdfModal/usePreviewLoanProposal";
import { GenerateLoanEstimateVariables }    from "./__types__/GenerateLoanEstimate";
import { GenerateLoanEstimate }             from "./__types__/GenerateLoanEstimate";
import { UPDATE_LOAN_ESTIMATE_OFFER }       from "./OfferTable/Offer";
import { WHEN_TITLE_FEES_EXISTS }           from "./OfferTable/offer.conditions";
import { WHEN_RATES_EXISTS }                from "./OfferTable/offer.conditions";
import { UpdateLoanEstimateOfferVariables } from "./OfferTable/Offer/__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "./OfferTable/Offer/__types__/UpdateLoanEstimateOffer";
import { Proposal }                         from "./Proposal";
import { useAppendObCustomFields }          from "./useDefaultOffer";

export const MakeProposal: FC = React.memo(function MakeProposal() {
  const loanEstimate = useContext(Proposal.Context);
  const { $object: { objectId } } = useSource();
  const navigate = useNavigate();
  const { $object } = useSource();
  const { preview, modalContext } = usePreviewLoanProposal(objectId);
  const [generate, { loading: loadingGenerate }] = useMutation<GenerateLoanEstimate, GenerateLoanEstimateVariables>(GENERATE_LOAN_ESTIMATE, {
    refetchQueries: ["GetLoanEstimate"]
  });
  const [update, { loading }] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(useAppendObCustomFields(UPDATE_LOAN_ESTIMATE_OFFER));
  const evaluate = useLazyCondition({
    conditions: WHEN_RATES_EXISTS
  });
  const evaluateTitleFee = useLazyCondition({
    conditions: WHEN_TITLE_FEES_EXISTS
  });
  const offersReady = useMemo(() => {
    return loanEstimate?.offers?.edges.every(offer => {
      return offer.node.isValid && !offer.node.isDirty && evaluate({ source: offer.node }).apply && evaluateTitleFee({ source: offer.node }).apply;
    });
  }, [loanEstimate]);
  const saveOffer = useCallback(async (offer: LoanEstimateOffer) => {
    const { objectId, id, ...rest } = offer;
    const params = rest.optimalBlue ? {
      ...rest, optimalBlue: omit(rest.optimalBlue, ["__typename"]
      )
    } : rest;
    await update({
      variables: {
        input: {
          id: objectId,
          fields: {
            ...omit({
              ...params,
              mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech
            }, ["__typename", "objectIcon", "isDirty", "isValid", "isTitleFeeEditable", "propertyAnnualInsuranceUnit", "propertyAnnualTaxUnit"])
          }
        }
      }
    });
  }, [update]);
  const handleClose = () => {
    setTimeout(() => {
      navigate(`/lead/${$object.objectId}/loan-proposal-beta`);
    });
  };

  const onMakeProposal = useCallback(async () => {
    await Promise.all(loanEstimate.offers?.edges.map((o) => saveOffer(o.node)));

    const { data: { generateLoanEstimate } } = await generate({ variables: { id: loanEstimate.objectId } });
    preview({
      file: generateLoanEstimate.file,
      loanEstimateId: generateLoanEstimate.objectId,
      image: generateLoanEstimate.image,
      onCancel: handleClose
    });
  }, [saveOffer, loanEstimate.offers?.edges, loanEstimate?.objectId]);

  return <>
    {modalContext}
    <Button
      disabled={!offersReady || loading || loadingGenerate}
      onClick={onMakeProposal}
      size={"xs"}>
      MAKE PROPOSAL
    </Button>
  </>;
});

const GENERATE_LOAN_ESTIMATE = gql`
  mutation GenerateLoanEstimate($id:String!){
    generateLoanEstimate(id:$id){
      ...LoanEstimate
    }
  }
  ${LOAN_ESTIMATE}
`;
