import { useQuery }                    from "@apollo/client";
import { gql }                         from "@apollo/client";
import { useField }                    from "@relcu/form";
import { useMemo }                     from "react";
import { useContext }                  from "react";
import { GetCountyLoanLimitVariables } from "./__types__/GetCountyLoanLimit";
import { GetCountyLoanLimit }          from "./__types__/GetCountyLoanLimit";
import { LoanCriteriaContext }         from "./LoanCriteriaProvider";

export function useLoanLimit() {
  const { product: { type } } = useContext(LoanCriteriaContext);
  const { input: { value: county } } = useField("property.propertyAddress.county", { subscription: { value: true } });
  const { input: { value: stateCode } } = useField("property.propertyAddress.state", { subscription: { value: true } });
  const where = useMemo(() => {
    const w: any = {};
    if (county) {
      w.countyName = {
        matchesRegex: county,
        options: "i"
      };
    }

    if (stateCode) {
      w.stateCode = {
        equalTo: stateCode
      };
    }

    return w;
  }, [stateCode, county]);

  const { input: { value: propertyType } } = useField("property.type", { subscription: { value: true } });
  const { data: { countyLoanLimits } = {} } = useQuery<GetCountyLoanLimit, GetCountyLoanLimitVariables>(GET_COUNTY_LOAN_LIMITS, {
    variables: { where }
  });
  if (type === "va") {
    return Infinity;
  }
  const loanLimits = countyLoanLimits?.edges[ 0 ]?.node;
  const loanLimit = type === "conventional" ? loanLimits?.conventionalLoanLimit : loanLimits?.fhaLoanLimit;
  const limitMapping = {
    "1_unit": "oneUnitLimit",
    "2_unit": "twoUnitLimit",
    "3_unit": "threeUnitLimit",
    "4_unit": "fourUnitLimit"
  };
  return limitMapping[ propertyType ] ? loanLimit?.[ limitMapping[ propertyType ] ] : loanLimit?.oneUnitLimit;
}

export const GET_COUNTY_LOAN_LIMITS = gql`
  query GetCountyLoanLimit($where:CountyLoanLimitWhereInput!){
    countyLoanLimits(where:$where){
      edges{
        node{
          fhaLoanLimit{
            oneUnitLimit
            twoUnitLimit
            threeUnitLimit
            fourUnitLimit
          }
          conventionalLoanLimit{
            oneUnitLimit
            twoUnitLimit
            threeUnitLimit
            fourUnitLimit
          }
        }
      }
    }
  }
`;
