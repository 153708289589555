import { emailTemplate } from "./helpers";
import { QuotedHTML }    from "./QuotedHTML";

export const prepareMessageHtml = (html = "", $settings, user) => {
  if ($settings?.emailSignature && $settings?.emailSignature[ 0 ]?.signature && $settings?.emailSignature[ 0 ]?.enabled) {
    const signature = emailTemplate($settings?.emailSignature[ 0 ]?.signature, user);
    html = applySignature(html, signature);
  }
  return html;
};

export const applySignature = (body = "", signature) => {
  const signatureRegex = new RegExp(/(<br\/>){0,2}<signature>[^]*<\/signature>/);

  let newBody = body;
  let paddingBefore = "";

  newBody = newBody.replace(signatureRegex, "");
  const signatureInPrevious = newBody !== body;

  let insertionPoint = newBody.search(new RegExp(/<\w+[^>]*gmail_quote/i));
  if (insertionPoint === -1) {
    insertionPoint = newBody.length;
    if (!signatureInPrevious) {
      paddingBefore = "<br><br>";
    }
  }

  const contentBefore = newBody.slice(0, insertionPoint);
  const contentAfter = newBody.slice(insertionPoint);
  return `${contentBefore}${paddingBefore}<signature>${signature}</signature>${contentAfter}`;
};

export const prepareTemplateHtml = () => {
  return document.querySelector("email-visualizer").shadowRoot.querySelector("div div").innerHTML.replaceAll(`contenteditable="true"`, "");
};

export const cleanPlainTextBody = (body) => {
  let cleanBody = body;
  const leadingOrTrailingTabs = /(?:^\t+|\t+$)/gmi;
  cleanBody = cleanBody.replace(leadingOrTrailingTabs, "");
  const manyNewlines = /\n{3,}/gi;
  cleanBody = cleanBody.replace(manyNewlines, "\n\n");
  const manySpaces = /\n{5,}/gi;
  cleanBody = cleanBody.replace(manySpaces, "    ");
  return cleanBody;
};

export const prepareMessageText = (html) => {
  const dom = new QuotedHTML().removeQuotedHTML(html || "", { asDOM: true });
  return cleanPlainTextBody(dom.body.innerText);
};