import React                                from "react";
import { useContext }                       from "react";
import { useState }                         from "react";
import { useMemo }                          from "react";
import { Typography }                       from "@relcu/rc";
import { gql }                              from "@apollo/client";
import { useMutation }                      from "@apollo/client";
import { useApolloClient }                  from "@apollo/client";
import { useForm }                          from "@relcu/final-form";
import { Field }                            from "@relcu/final-form";
import { Icon }                             from "@relcu/rc";
import { IconButton }                       from "@relcu/rc";
import { ToggleDropdown }                   from "@relcu/rc";
import { HeaderCell }                       from "@relcu/rc";
import { FieldCell }                        from "@relcu/rc";
import { useSource }                        from "@relcu/ui";
import { omit }                             from "@relcu/ui";
import { LoanEstimateOffer }                from "../../../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }              from "../../../../../../graph/operations.graphql";
import { LOAN_ESTIMATE }                    from "../../../../../../graph/operations.graphql";
import { Proposal }                         from "../../Proposal";
import { GetLoanEstimateVariables }         from "../../Proposal/__types__/GetLoanEstimate";
import { GetLoanEstimate }                  from "../../Proposal/__types__/GetLoanEstimate";
import { GET_LOAN_ESTIMATE }                from "../../Proposal/ProposalProvider";
import { useAppendObCustomFields }          from "../../useDefaultOffer";
import { createDefaultOfferEdge }           from "../../utils";
import { CreateLoanEstimateOfferVariables } from "./__types__/CreateLoanEstimateOffer";
import { CreateLoanEstimateOffer }          from "./__types__/CreateLoanEstimateOffer";
import { DeleteLoanEstimateOfferVariables } from "./__types__/DeleteLoanEstimateOffer";
import { DeleteLoanEstimateOffer }          from "./__types__/DeleteLoanEstimateOffer";
import { UpdateLoanEstimateOfferVariables } from "./__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "./__types__/UpdateLoanEstimateOffer";
import { UPDATE_LOAN_ESTIMATE_OFFER }       from "./Offer";
import { CREATE_LOAN_ESTIMATE_OFFER }       from "./Offer";

const DELETE_LOAN_ESTIMATE_OFFER = gql`
  mutation DeleteLoanEstimateOffer($id:ID!){
    deleteLoanEstimateOffer(input: {id:$id}){
      loanEstimateOffer {
        id
      }
    }
  }
`;

export const OfferHeader = React.memo(() => {
  const client = useApolloClient();
  const form = useForm<LoanEstimateOffer>();
  const { $object: lead } = useSource();
  const loanEstimate = useContext(Proposal.Context);
  const [isRename, setIsRename] = useState(false);
  const [create] = useMutation<CreateLoanEstimateOffer, CreateLoanEstimateOfferVariables>(useAppendObCustomFields(CREATE_LOAN_ESTIMATE_OFFER), {
    update(cache, { data: { createLoanEstimateOffer: { loanEstimateOffer } } }) {
      const { loanEstimates } = cache.readQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true },
            deleted: { equalTo: false }
          }
        }
      });
      cache.writeQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        data: {
          loanEstimates: {
            ...loanEstimates,
            edges: [
              {
                ...loanEstimates.edges[ 0 ],
                node: {
                  ...loanEstimates.edges[ 0 ].node,
                  offers: {
                    ...loanEstimates.edges[ 0 ].node.offers,
                    edges: [
                      ...loanEstimates.edges[ 0 ].node.offers.edges,
                      {
                        node: {
                          ...loanEstimateOffer,
                          isValid: true,
                          isDirty: false,
                          isTitleFeeEditable: false,
                          propertyAnnualInsuranceUnit: "currency",
                          propertyAnnualTaxUnit: "currency"
                        },
                        __typename: "LoanEstimateOfferEdge"
                      }
                    ]
                  }
                }
              }
            ]
          }
        },
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true }
          }
        }
      });
    }
  });
  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(useAppendObCustomFields(UPDATE_LOAN_ESTIMATE_OFFER));
  const [remove] = useMutation<DeleteLoanEstimateOffer, DeleteLoanEstimateOfferVariables>(DELETE_LOAN_ESTIMATE_OFFER, {
    refetchQueries: ["GetDefaultOffer"]
  });
  const handleSave = async () => {
    setIsRename(false);
    const { values, initialValues } = form.getState();
    if (!values.objectName) {
      form.change("objectName", initialValues.objectName);
    } else if (values.objectId) {
      await update({
        variables: {
          input: {
            id: values.objectId,
            fields: {
              objectName: values.objectName
            }
          }
        }
      });
    }
  };
  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      handleSave();
    }
  };
  const onRemove = async () => {
    document.querySelector("#app").removeAttribute("inert");
    const { values } = form.getState();
    if (values.objectId) {
      await remove({
        variables: {
          id: values.id
        }
      });
    }
    client.writeFragment({
      fragment: useAppendObCustomFields(LOAN_ESTIMATE),
      fragmentName: "LoanEstimate",
      data: {
        ...loanEstimate,
        offers: {
          ...loanEstimate.offers,
          edges: loanEstimate.offers.edges.filter(e => e.node.id !== values.id)
        }
      },
      id: client.cache.identify({ __typename: loanEstimate.__typename, id: loanEstimate.id })
    });
  };
  const onCopy = () => {
    document.querySelector("#app").removeAttribute("inert");
    const { values } = form.getState();
    const { id, ...data } = values;
    const objectName = `Copy of ${data.objectName}`;

    if (values.objectId) {
      const { id, objectId, points, __typename, mortech, objectIcon, isValid, isTitleFeeEditable, isDirty, propertyAnnualTaxUnit, propertyAnnualInsuranceUnit, createdAt, ...rest } = Object(data);
      const loanEstimateOffer = client.readFragment<LoanEstimateOffer>({
        fragment: useAppendObCustomFields(LOAN_ESTIMATE_OFFER),
        fragmentName: "LoanEstimateOffer",
        id: client.cache.identify({ __typename: "LoanEstimateOffer", id: values.id })
      });
      const offer = omit(loanEstimateOffer, ["id", "objectId", "points", "__typename", "mortech", "objectIcon", "isValid", "isTitleFeeEditable", "propertyAnnualInsuranceUnit", "propertyAnnualTaxUnit", "isDirty", "createdAt", "optimalBlue"]);
      const params = rest.optimalBlue ? {
        ...rest, optimalBlue: omit(rest.optimalBlue, ["__typename"]
        )
      } : rest;
      return create({
        variables: {
          input: {
            fields: {
              ...params,
              ...offer,
              objectName,
              mortech: mortech ? omit(mortech, ["__typename"]) : mortech,
              loanEstimate: {
                link: loanEstimate.id
              },
              lead: {
                link: lead.id
              }
            }
          }
        }
      });
    } else {
      client.writeFragment({
        fragment: useAppendObCustomFields(LOAN_ESTIMATE),
        fragmentName: "LoanEstimate",
        data: {
          ...loanEstimate,
          offers: {
            ...loanEstimate.offers,
            edges: [
              ...loanEstimate.offers.edges,
              createDefaultOfferEdge({ ...data, objectName })
            ]
          }
        },
        id: client.cache.identify({ __typename: loanEstimate.__typename, id: loanEstimate.id })
      });
    }
  };
  const isSaved = !!form.getState().values?.objectId;
  return (
    <>
      {isRename ?
        <div style={{ background: "white", position: "sticky", top: 0, zIndex: 2 }}>
          <FieldCell highlight={false} name={"objectName"}
                     properties={{ autoFocus: true, onBlur: handleSave, onKeyPress: handleEnter }}/>
        </div> :
        <HeaderCell style={{ background: "var(--rc-background-tint3)", position: "sticky", top: 0, zIndex: 2 }}>
          <Field name={"objectName"} subscription={{ value: true }}>
            {({ input }) =>
              <Typography
                weights={"medium"}
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {input.value}
              </Typography>}
          </Field>
          <ToggleDropdown
            toggleRenderer={
              <IconButton
                size={"xs"}
                appearance={"subtle"}
                style={{ flexShrink: 0 }}
                icon={<Icon type={"more_vert"}/>}
              />}
            placement={"bottomEnd"}>
            {isSaved && loanEstimate.offers.edges.length < 3 &&
              <ToggleDropdown.Item onSelect={onCopy}
                                   icon={<Icon type={"file_copy"}/>}
                                   eventKey={2}>
                Duplicate to customize
              </ToggleDropdown.Item>}
            <ToggleDropdown.Item
              onClick={() => {
                document.querySelector("#app").removeAttribute("inert");
                setIsRename(true);
              }}
              icon={<Icon type={"create"}/>}
              eventKey={3}>
              Rename
            </ToggleDropdown.Item>
            {loanEstimate.offers.edges.length > 1 &&
              <ToggleDropdown.Item
                onClick={onRemove}
                icon={<Icon type={"delete"}/>}
                eventKey={4}>
                Remove
              </ToggleDropdown.Item>
            }
          </ToggleDropdown>
        </HeaderCell>
      }
    </>
  );
});
