import { TypePolicy } from "@apollo/client";
import { Root } from "../__types__/Root";
import { ROOT } from "../operations.graphql";

export const ReferralPartner: TypePolicy = {
  fields: {
    notifications: {
      read(exising, { readField, cache }) {
        const id: string = readField("id");
        const { notifications } = cache.readQuery<Root>({ query: ROOT });
        const leadNotifications = notifications.edges.filter(({ node }) => node.scope?.id == id).map((n) => n.node);
        return leadNotifications.length;
      }
    }
  }
};
