import React                                           from "react";
import { FC }                                          from "react";
import { FieldCell }                                   from "@relcu/rc";
import { FormWhere }                                   from "@relcu/ui";
import { format }                                      from "../../../../../../utils/helpers";
import { useBetaLoanLimit }                            from "../../useBetaLoanLimit";
import { WHEN_LOAN_AMOUNT_EXCEEDS_AVERAGE_LOAN_LIMIT } from "../offer.conditions";

export const LoanAmountCell: FC<any> = React.memo(function LoanAmountCell(props) {
  const limit = useBetaLoanLimit();

  return <FormWhere
    conditions={WHEN_LOAN_AMOUNT_EXCEEDS_AVERAGE_LOAN_LIMIT}
    source={{
      $limit: limit
    }}>
    {
      ({ apply }) => (
        <FieldCell.Number
          status={apply ? "warning" : limit != Infinity ? "info" : null}
          required
          helperText={apply ? "Loan amount exceeds maximum loan limit." : limit != Infinity ? `Maximum loan amount $${format(limit, 2)}` : "Value can not be equal to zero or empty"}
          name={"loanAmount"}
          icon={"attach_money"}
        />
      )
    }
  </FormWhere>;

});
