import { useContext }          from "react";
import { FC }                  from "react";
import { useMemo }             from "react";
import { FunctionComponent }   from "react";
import { ComponentType }       from "react";
import React                   from "react";
import { FormSpy }             from "@relcu/form";
import { JsonFormContext }     from "../../..";
import { Where }               from "../../..";
import { TopLevelCondition }   from "../../..";
import { permissionUtils }     from "../../..";
import { JsonPermissions }     from "../../..";
import { useComponents }       from "../Component";
import { DetailsSectionProps } from "../../Section";
import { Section }             from "../../Section";
import { Viewer }              from "../JsonPage";
import { JsonField }           from "./JsonField";
import { JsonFieldProps }      from "./JsonField";

export const JsonSection: FC<JsonSectionProps> = React.memo(withCondition(function JSONSection(props) {
  let { fields, view, mode: formMode, path, component, user, properties, children, ...rest } = props;
  const { sections } = useComponents();
  const ComponentType = sections[ component ] as FunctionComponent<JsonSectionProps>;
  fields = useMemo(() =>
      fields.filter(f => permissionUtils.hasAccess(Object(user), f.permissions, mode(view)))
    , [fields, view, user]);
  if (ComponentType) {
    return <ComponentType {...props} {...properties} fields={fields}/>;
  }
  return (
    <Section view={view} {...rest} >
      {
        fields.map((field, index) => {
          return (
            <JsonField
              key={index}
              {...field}
              mode={formMode}
              name={path ? [path, field.name].join(".") : field.name}
              view={mode(view)}
            />
          );
        })
      }
      {children}
    </Section>
  );
}));
export interface JsonSectionProps extends DetailsSectionProps {
  path?: string;
  user?: Partial<Viewer>;
  component?: string;
  fields: JsonFieldProps[];
  properties?: Record<string, any>;
  permissions?: JsonPermissions;
  conditions?: TopLevelCondition;
  mode?: "create" | "edit";
  showTabs?: boolean;
}

function withCondition(JsonSection) {
  return function SectionConditionHok(props: JsonSectionProps) {
    if (props?.conditions) {
      const { conditions, ...properties } = props;
      const context = useContext(JsonFormContext);
      return (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <Where conditions={conditions} source={{ ...context.initialValues, ...values }}>
              <JsonSection {...properties} />
            </Where>
          )}
        </FormSpy>
      );
    }
    return <JsonSection {...props}/>;
  };
}
export function mode(view) {
  return ["create", "write"].includes(view) ? "write" : "read";
}
