import React                 from "react";
import { FC }                from "react";
import { useState }          from "react";
import { useMemo }           from "react";
import { UseFieldConfig }    from "@relcu/final-form";
import { Fields }            from "@relcu/gql-query-builder";
import { SelectPicker }      from "@relcu/rc";
import { SelectPickerProps } from "@relcu/rc";
import { JsonPermissions }   from "@relcu/ui";
import { getSelectionSet }   from "../../../../utils/graphQlUtils";
import { toFirstUpper }      from "../../../../utils/helpers";
import { toFirstLower }      from "../../../../utils/helpers";
import { pluralize }         from "../../../../utils/pluralize";
import { useJqlQuery }       from "../../../Layout/Jql";

export interface BaseFieldProps extends Omit<UseFieldConfig<any>, "value"> {
  targetClass?: string | string[];
  targetField?: string;
  permissions?: JsonPermissions;
  readPlaceholder?: string;
}

export interface PointerPickerValue {
  objectId: string;
  objectName?: string;
  objectIcon?: string;
  __typename: string;
  [ k: string ]: any;
}

export interface PointerPickerProps extends BaseFieldProps, Omit<SelectPickerProps, "data"> {
  fields?: Fields;
  data?: PointerPickerValue[];
  filters?: object;
}

export const PointerPicker: FC<PointerPickerProps> = React.memo(function PointerPicker({ fields, targetClass, ...props }) {
  const first = useMemo(() => {
    return Array.isArray(targetClass) ? 4 : 10;
  }, []);
  const name = useMemo(() => {
    if(props.name.includes(".")) {
      return props.name.split(".")[1]
    }

    return props.name
  }, [props.name])
  const [q, setQ] = useState("");
  const className = Array.isArray(targetClass) ? targetClass[ 0 ] : targetClass;
  const operation = useMemo(() => pluralize(toFirstLower(className)), [className]);
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const initialQuery = useMemo(() => {
    return {
      operation,
      variables: {
        first: 10,
        where: {
          type: `${className}WhereInput`,
          value: {
            [ props.valueKey ]: {
              equalTo: props.value
            }
          }
        }
      },
      fields: [
        {
          edges: [
            {
              node: selectionSet
            }
          ]
        }
      ]
    };
  }, [props.value]);
  const { data: initial = { [ operation ]: { edges: [] } } } = useJqlQuery(initialQuery, {
    // skip: props.view === "read",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `Initial${toFirstUpper(name)}PointerSelector`
  });
  const query = useMemo(() => {
    let query: any;
    const variables = {
      first
    };

    if (q) {
      variables[ "search" ] = `${(q || "").trim()}`;
    }

    if (Array.isArray(targetClass)) {
      query = targetClass.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            ...variables,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value: {
                ...props.filters
              }
            }
          },
          fields: [
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      variables[ "first" ] = 10;
      query = {
        operation,
        variables: {
          ...variables,
          where: {
            type: `${className}WhereInput`,
            value: {
              ...props.filters
            }
          }
        },
        fields: [
          {
            edges: [
              {
                node: selectionSet
              }
            ]
          }
        ]
      };
    }
    return query;
  }, [q, targetClass, props.filters]);

  const { data = { [ operation ]: { edges: [] } }, loading } = useJqlQuery(query, {
    // skip: props.view === "read",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `${toFirstUpper(name)}PointerSelector`
  });
  const options = useMemo(() => {
    const items = [];
    if (Array.isArray(targetClass)) {
      Object.keys(data).forEach(t => {
        const label = t == "leadSources" ? "CAMPAIGN" : t.toUpperCase();
        const options = data[ t ].edges.map(({ node }) => node);
        if (options.length) {
          items.push({
            label,
            options: data[ t ].edges.map(({ node }) => node)
          });
        }
      });
      return items;
    }
    items.push(...(
      Object(data[ operation ])[ "edges" ] || []
    ).map(({ node }) => node));
    const isValueNotThere = !items.find(item => item[ props.valueKey ] == props.value);
    const [selectedItem] = (Object(initial[ operation ])[ "edges" ] || []).map(({ node }) => node);
    if (isValueNotThere && selectedItem) {
      items.push(selectedItem);
    }
    return items;
  }, [data, operation, initial]);

  //todo in parallel with gql query selectPicker filtering it's own list but I think loading flag should fix visual bug
  return <SelectPicker
    loading={loading}
    onSearch={(searchKeyword) => setQ(searchKeyword)}
    data={options}
    {...props}
  />;
});
