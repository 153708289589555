import React                             from "react";
import { FC }                            from "react";
import { useEffect }                     from "react";
import { useMemo }                       from "react";
import { useState }                      from "react";
import { useFormState }                  from "@relcu/final-form";
import { RelcuMilestone }                from "@relcu/ui";
import { RelcuMilestoneInfo }            from "@relcu/ui";
import { DateTime }                      from "luxon";
import { useSearchParams }               from "@relcu/react-router";
import { useMutation }                   from "@apollo/client";
import { IconType }                      from "@relcu/ui";
import { Box }                           from "@relcu/ui";
import { JsonSectionProps }              from "@relcu/ui";
import { DATE_DIFFERENT, Section }       from "@relcu/ui";
import { EmptyList }                     from "@relcu/ui";
import { Slider }                        from "../../../../Slider";
import { SliderClasses }                 from "../../../../Slider";
import { useUnreadNotifications }        from "../../../../useUnreadNotifications";

export interface RelcuMilestoneTask {
  milestoneLosId: string
  losId: string;
  completed: boolean;
  title: string;
}

export interface RelcuMilestoneDocument {
  slug: string
  milestoneLosId: string
  losId: string;
  title: string;
  status: string;
  receivedDate?: string;
  requestedDate?: string;
  createdDate?: string;
}

export interface RelcuMilestoneInter {
  losId: string
  slug: string
  startDate: string
  plannedDate: string
  done: boolean
  associate: string
  name: string
  comment: string
  tasks?: RelcuMilestoneTask[],
  documents?: RelcuMilestoneDocument[],
}

export interface RelcuMilestoneSectionProps extends Omit<JsonSectionProps, "fields"> {
  icon?: IconType;
  loanId: string;
}

export const RelcuMilestoneSection: FC<RelcuMilestoneSectionProps> = React.memo(function RelcuMilestoneSection(props) {
    const { title, icon, loanId, showTabs = true } = props;
    const formState = useFormState({ subscription: { values: true } });
    const { milestones = [] } = formState?.values;
    const [searchParams] = useSearchParams();
    const urlSelected = searchParams.get("from");
    //const urlSelectedMilestone = useMemo(() => milestones.find(m => m.losId == urlSelected), [urlSelected]);
    const [selectedId, setSelectedId] = useState(urlSelected ?? (milestones[ 0 ]?.losId || null));
    const selected = useMemo(() => {
      let milestone = milestones.find(m => m.losId == selectedId);
      return milestone || milestones[ 0 ];
    }, [selectedId, milestones]);
    const notification = useUnreadNotifications();
    const { edges = [] } = notification || {};
    //const unreadMilestoneNotifications = edges.filter(e => e.node.type === "milestone_comment");
    const unreadMilestoneNotifications = useMemo(() => {
      return edges.filter(e => (e.node.type === "milestone_comment" && e.node.data.loanId == loanId));
    }, [loanId, edges]);
    const selectedUnreadNotifications = useMemo(() => unreadMilestoneNotifications?.filter(({ node }) => node.data.milestoneId == selected?.losId).map((n) => n.node.objectId), [selected, unreadMilestoneNotifications]);
    const checkTimeDiff = (time, diffTime) => {
      const diff = time.diff(diffTime, DATE_DIFFERENT);
      return diff.toObject();
    };

    const calculateDate = (current, prev) => {
      const currentDate = DateTime.fromISO(current.startDate);
      const prevDate = DateTime.fromISO(prev.startDate);

      if (current.done) {
        return checkTimeDiff(currentDate, prevDate);
      } else if (!current.done && prev.done) {
        return checkTimeDiff(currentDate, DateTime.now());
      }

      return "";
    };

    useEffect(() => {
      if (!selectedId) {
        setSelectedId(milestones[ 0 ]?.losId);
      }
    }, [milestones, selectedId, setSelectedId]);

    useEffect(() => {
      setSelectedId(urlSelected);
    }, [urlSelected]);
    return <Section title={title} bodyProps={{ style: { overflowX: "hidden", display: "block" } }} editable={false}>
      {
        !milestones?.length ?
          <Box container flex={1} justify={"center"}>
            <EmptyList icon={icon} content={"There is no milestones on this loan."}
                       title={"No milestones found"}/>
          </Box>

          :
          <>
            <Slider alignItems={"end"}>
              {milestones.map((milestone, index, milestones) => {
                  const prevMilestone = milestones[ index - 1 ];
                  const nextMilestone = milestones[ index + 1 ];
                  const currentMileStone = (prevMilestone && prevMilestone?.done) && !milestone.done;
                  const passed = milestone.done && (prevMilestone?.done || !prevMilestone);

                  return <RelcuMilestone
                    unread={!!unreadMilestoneNotifications.find(({ node }) => node.data.milestoneId == milestone.losId)}
                    user={milestone.associate}
                    active={currentMileStone}
                    selected={milestone.losId == selectedId}
                    className={SliderClasses.Item}
                    key={milestone.losId}
                    title={milestone.name}
                    passed={passed}
                    disabled={!currentMileStone && !milestone.done && !nextMilestone?.done}
                    date={!prevMilestone ? DateTime.fromISO(milestone.startDate).toFormat("MM.dd.yyyy") : null}
                    dateDiff={prevMilestone && calculateDate(milestone, prevMilestone)}
                    id={milestone.losId}
                    onClick={(e) => setSelectedId(milestone.losId)}
                  />;
                }
              )}
            </Slider>
            <RelcuMilestoneInfo
              key={selectedId}
              losId={selectedId}
              tasks={selected.tasks}
              documents={selected.documents}
              comment={selected?.comment}
              unread={!!unreadMilestoneNotifications.length}
              showTabs={showTabs}
            />
          </>
      }
    </Section>;
  }
);

RelcuMilestoneSection.defaultProps = {};


