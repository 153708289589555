import React                   from "react";
import { useContext }          from "react";
import { useField }            from "@relcu/form";
import { isPositiveInteger }   from "../../../../../../utils/helpers";
import { LoanCriteriaContext } from "../LoanCriteriaProvider";
import { CurrencyField }       from "@relcu/ui";
import { useLoanLimit }        from "../useLoanLimit";

export const LoanAmountField = React.memo(function LoanAmountField() {
  const limit = useLoanLimit();
  const { calculate, product: { type, name }, settings } = useContext(LoanCriteriaContext);
  const { input: { value: loanAmount } } = useField("loanAmount", { subscription: { value: true } });
  const showWarning = !["va", "fha"].includes(type);
  let warning: string;
  if (loanAmount > limit) {
    warning = "Loan amount exceeds maximum loan limit.";
  } else if (loanAmount > settings.averageLoanLimit && !name?.toLowerCase().includes("jum")) {
    warning = "Loan amount exceeds average loan limit.";
  }

  return <CurrencyField  view={"write"} validate={value => isPositiveInteger(value)} required={true}
                        onBlur={() => calculate("loanAmount")}
                        warning={showWarning ? warning : undefined} flex={1}
                        name={"loanAmount"}
                        label={"Loan amount"} placeholder={"Enter loan amount"}/>;
});
