import React                            from "react";
import { FC }                           from "react";
import { Box }                          from "@relcu/ui";
import { SidebarClasses }               from "./SidebarClasses";
import { SidebarHeader }                from "./SidebarHeader";
import { TableSidebarDefaultItemProps } from "./SidebarItem";
import { SidebarItem }                  from "./SidebarItem";
import { TableSidebarDefaultItem }      from "./SidebarItem";
import { TableSidebarItemProps }        from "./SidebarItem";
import { SidebarSubHeader }             from "./SidebarSubHeader";

export interface TableSidebarProps {
  defaultItem?: TableSidebarDefaultItemProps
  title?: string
  onClose?: () => void
  items: { [ title: string ]: TableSidebarItemProps[] } | TableSidebarItemProps[]
}

export const Sidebar: FC<TableSidebarProps> = React.forwardRef(function TableViewSideBar(props, ref) {
  const [height, setHeight] = React.useState(0);
  const el = React.useRef(null);

  React.useEffect(() => {
    if (el.current) {
      const resizeObserver = new ResizeObserver((event) => {
        let height = event[ 0 ].contentBoxSize[ 0 ].blockSize;
        setHeight(height);
      });

      resizeObserver.observe(el.current);
      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }

  }, [ref]);
  const { title, onClose, defaultItem, items } = props;
  return <Box flexBasis={240} ref={el}>
    <Box container style={{ height: height }} direction={"column"} className={SidebarClasses.Sidebar}>
      <SidebarHeader title={title} onClose={onClose}/>
      {!!defaultItem && <TableSidebarDefaultItem {...defaultItem} />}
      <Box container direction={"column"} style={{ overflowY: "auto" }}>
        {Array.isArray(items) ?
          <Box container direction={"column"} gap={"XXS"}>
            {items.map((item, index) => <SidebarItem key={index} {...item}/>)}
          </Box> :
          <>
            {Object.keys(items).map((title, i) => (
              !!items[ title ].length && <div key={i}>
                <SidebarSubHeader>{title}</SidebarSubHeader>
                <Box container direction={"column"} gap={"XXS"}>
                  {items[ title ].map((item, index) => <SidebarItem key={index} {...item}/>)}
                </Box>
              </div>
            ))}
          </>
        }
      </Box>
    </Box>
  </Box>;
});
