import { useMemo }             from "react";
import { useCallback }         from "react";
import { useState }            from "react";
import { FormApi }             from "@relcu/form";
import { StepInterface }       from "@relcu/ui";
import { defaultValidator }    from "@relcu/ui";
import { PageViewDialogProps } from "./PageViewDialog";
import { Columns }             from "./Steps/Columns";
import { Filter }              from "./Steps/Filter";
import { Name }                from "./Steps/Name";
import { Share }               from "./Steps/Share";

export function usePageViewDialog(props: PageViewDialogProps) {
  const { initialStep, data: { selectedView, availableFields }, onConfirm, edit, share } = props;
  const [step, setStep] = useState(initialStep ?? 0);
  const handleSubmit = useCallback((data) => {
    const errors: any = {};
    if (!data.name?.trim()) {
      errors.name = "Name field is required";
    } else if (!data.filter || (data.filter && !Object.keys(data.filter).length)) {
      errors.filter = "Filtering is incomplete. Make sure to complete all mandatory steps to proceed.";
    } else if (!data.columns.length) {
      errors.columns = "There's should be at least one selected field.";
    } else {
      const valid = !Object.values(defaultValidator(data.filter)).filter(rv => (typeof rv !== "boolean") || rv === false).length;
      if (!valid) {
        errors.filter = "Filtering is incomplete. Make sure to complete all mandatory steps to proceed.";
      }
    }

    if (!Object.keys(errors).length) {
      onConfirm({
        ...data,
        name: data.name.trim(),
        columns: data.columns.map(selected => {
          return {
            key: selected.data.key,
            fixed: selected.data.fixed ?? null,
            sort: selected.data.sort,
            width: selected.data.width
          };
        })
      });
    }
    return errors;
  }, [onConfirm]);
  const steps: StepInterface[] = useMemo(() => {
    const steps = [
      {
        title: "Name",
        name: "name",
        component: Name
      },
      {
        title: "Filter",
        name: "filter",
        component: Filter
      },
      {
        title: "Columns",
        name: "columns",
        component: Columns
      }
    ];

    if (share) {
      steps.push({
        title: "Share",
        name: "share",
        component: Share
      });
    }
    return steps;
  }, [share]);
  const changeStep = useCallback((e, form: FormApi<any>) => {
    e.stopPropagation();
    e.preventDefault();
    const values = form.getState().values;
    const errors: any = {};
    if (!values.name) {
      errors.name = "Name field is required";
    }
  }, [steps, step]);
  const initialValues = useMemo(() => {
    return {
      id: selectedView?.id,
      name: selectedView?.name,
      filter: selectedView?.filter ? selectedView.filter : (props.schemaName === "Lead" && edit == false) ? {
        "combinator": "and",
        "rules": [{
          "field": "duplicateOf",
          "id": "r-default-1",
          "operator": "is null",
          "valueSource": "value",
          "value": ""
        }]
      } : {},
      columns: edit == false ? availableFields.filter(f => f.visible).map(({ name, visible, ...rest }) => ({
        id: name,
        column: "selected",
        data: {
          key: name,
          ...rest
        }
      })) : parseFields(),
      rPerm: selectedView?.rPerm || []
    };
  }, [selectedView, edit, availableFields]);

  function parseFields() {
    const columns = [];
    selectedView.fields.forEach(field => {
      const availableField = availableFields.find((afield, index) => afield.name == field.key);
      const { name, visible, ...rest } = availableField;
      columns.push({
        id: field.key,
        column: "selected",
        data: {
          ...rest,
          ...field
        }
      });
    });
    return columns;
  }

  return {
    initialValues,
    handleSubmit,
    steps,
    step,
    setStep,
    changeStep
  };
}
