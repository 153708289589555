import { BadgeColors }           from "@relcu/ui";
import { BadgeVariants }         from "@relcu/ui";
import { Badge }                 from "@relcu/ui";
import { timeTo }                from "@relcu/ui";
import { DateTime }              from "luxon";
import React                     from "react";
import { FC }                    from "react";
import { useClassNames }         from "@relcu/rc";
import { TypographyLine }        from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { NotificationIconColor } from "@relcu/ui";
import { Notification }          from "@relcu/ui";
import { NotificationHeader }    from "@relcu/ui";
import { NotificationContent }   from "@relcu/ui";
import { TypographyColor }       from "@relcu/ui";
import { TypographySize }        from "@relcu/ui";
import { Typography }            from "@relcu/ui";
import { NotificationProps }     from "../NotificationBar";
import "../notification-bar.css";

export const LeadAssignmentNotification: FC<NotificationProps> = React.memo(function LeadAssignmentNotification(props) {
  const { node: { type, createdAt, unread, record, targetObjectName } = {}, inApp, className, onNavigate, indicateStatus } = props;
  const { merge } = useClassNames("");
  const fontWeight = unread ? 500 : 400;
  return (
    <Notification onNavigate={onNavigate} className={className}  style={{ fontWeight }}>
      <NotificationHeader inApp={inApp} title={"New Lead"} icon={"rc_lead"} color={NotificationIconColor.Info}>
        {
          indicateStatus &&
          <Box container gap={"XXXS"} alignItems={"center"}>
            <Typography size={TypographySize.TextSmall} color={TypographyColor.Secondary} noWrap>
              {timeTo(DateTime.fromJSDate(new Date(createdAt)))}
            </Typography>
            {unread && <Badge variant={BadgeVariants.Dot} color={BadgeColors.Error}/>}
          </Box>
        }
      </NotificationHeader>
      <NotificationContent inApp content={<Box container gap={"XXXS"}>
        <Typography
          color={inApp && TypographyColor.White}
          lineHeight={inApp && TypographyLine.LineBody1}
          size={inApp ? TypographySize.Text : TypographySize.TextSmall}
        >
          {record.objectName} is assigned to you.
        </Typography>
      </Box>}/>
    </Notification>
  );
});
