import React                    from "react";
import { FC }                   from "react";
import { ReactNode }            from "react";
import { useEffect }            from "react";
import { useMemo }              from "react";
import { ComponentType }        from "react";
import { Navigate }             from "@relcu/react-router";
import { Route }                from "@relcu/react-router";
import { Routes }               from "@relcu/react-router";
import { useLazyCondition }     from "../../..";
import { transformNameToLabel } from "../../..";
import { JsonPermissions }      from "../../..";
import { classNames }           from "../../..";
import { ListViewBlank }        from "../../..";
import { HorizontalDivider }    from "../../..";
import { ListHeader }           from "../../..";
import { JsonSectionProps }     from "../../..";
import { JsonFieldProps }       from "../../..";
import { useSource }            from "../../..";
import { useComponents }        from "../../..";
import { DirectionCallOut }     from "../../..";
import { BadgeStatus }          from "../../..";
import { IconType }             from "../../..";
import { FontIcon }             from "../../..";
import { SubNavigationItem }    from "../../..";
import { Alignment }            from "../../..";
import { TopLevelCondition }    from "../../../";
import { useTitle }             from "../../../hooks/useTitle";
import { Box }                  from "../../Box";
import { ScrollBox }            from "../../ScrollBox";
import { SubNavigation }        from "../../SubNavigation";
import { Tooltip }              from "../../Tooltip";
import { NodeBubble }           from "./Bubble";
import { useJsonPage }          from "./useJsonPage";
import BetaUrl                  from "./beta.svg";

export const JsonPage: FC<JsonPageProps> = React.memo(function JsonPage(props) {
  const page = useJsonPage(props);
  const withGap = page.tabs.length == 1 ? page.tabs[ 0 ].properties.withGap ?? true : true;
  return <JsonPageRoot withGap={withGap} isFullScreen={props.isFullScreen}>
    {
      !props.isFullScreen &&
      <JsonPageHeader header={page.header} tabs={page.tabs}/>
    }
    <JsonPageContainer>
      <JsonPageNav tabs={page.tabs} stats={props.stats} isFullScreen={props.isFullScreen}/>
      <JsonPageView tabs={page.tabs} layout={page.layout}/>
      <JsonPageWidgets showWidgets={page.showWidgets} widgets={page.widgets}/>
    </JsonPageContainer>
  </JsonPageRoot>;
});
function JsonPageHeader(props: { tabs: ITabs, header: ElementInjection }) {
  const { $object } = useSource();
  const { headers } = useComponents();
  const status = $object.__typename == "User" ? $object.status : undefined;
  const Header = useMemo(() => {
    return headers[ props.header?.component ];
  }, [props.header, headers]);
  const withHeader = props.tabs.length == 1 ? props.tabs[ 0 ].properties.withHeader ?? true : true;

  if (!withHeader) {
    return null;
  }
  return <Box container direction={"column"} gap={"XXS"}>
    <Box container gap={"XXS"}>
      <Routes>
        {props.tabs.map((tab, i) => (
          <Route key={tab.title + i} path={tab.route || tab.path} element={
            <DirectionCallOut
              flexBasis={"50%"}
              header={tab?.title || ""}
              name={$object.objectName}
              icon={$object.objectIcon}
              type={transformNameToLabel($object.__typename)}
              status={status as BadgeStatus}
              deactivated={$object.deactivated}
            />
          }/>))}
      </Routes>
      {Header && <Header {...props.header.properties}/>}
    </Box>
    <HorizontalDivider style={{ height: "4px" }}/>
  </Box>;
}
function JsonPageRoot(props: { withGap: boolean, children: ReactNode, isFullScreen?: boolean }) {
  const className = classNames({
    [ JsonPageClasses.JsonPage ]: props.withGap,
    [ JsonPageClasses.JsonPageFull ]: props.isFullScreen
  });
  return (
    <Box container direction={"column"} flex={1} gap={"XXS"} className={className}
         style={{ overflowX: "hidden" }}>
      {props.children}
    </Box>
  );
}
function JsonPageContainer(props) {
  return (
    <Box container gap={"XS"} flex={"1 1 auto"} className={JsonPageClasses.JsonPageContainer}>
      {props.children}
    </Box>
  );
}
function JsonPageWidgets(page: { showWidgets: boolean, widgets: ElementInjections }) {
  if (!page.showWidgets) {
    return null;
  }
  const { widgets } = useComponents();
  const evaluate = useLazyCondition();
  const Widgets = useMemo(() => {
    return page.widgets.filter(w => {
      return !!w && (w.conditions ? evaluate({ conditions: w.conditions }).apply : true) && !!widgets[ w.component ];
    }).map(({ component, properties }, i) => {

      const Widget = widgets[ component ];
      return <Widget key={"widget" + i} {...properties}/>;
    });
  }, [widgets, page.widgets]);
  return (
    <ScrollBox flexShrink={0}>
      {Widgets}
    </ScrollBox>
  );
}

interface JsonPagNavProps {
  tabs: ITabs,
  stats: Stats
  isFullScreen?: boolean;
}
function JsonPageNav(props: JsonPagNavProps) {
  const { isFullScreen, tabs, stats } = props;
  if (tabs.length <= 1 || isFullScreen) {
    return null;
  }
  return (
    <SubNavigation direction={"column"} flexBasis={40} className={"json-page-navigation"}>
      {
        tabs.length <= 1 ? <></> :
          tabs.map((tab, key) => (
            <React.Fragment key={key}>
              <NodeBubble for={tab.path} stats={stats}>
                <Tooltip title={tab.title} alignment={Alignment.Right}>
                  <SubNavigationItem className={tab.beta && JsonPageClasses.NavigationWithIcon} path={tab.path}
                                     end={!tab.route}>
                    <FontIcon type={tab.icon as IconType}/>
                    {tab.beta && <div className={JsonPageClasses.BetaIcon}>
                      <img src={BetaUrl} alt={"Beta Version"}/>
                    </div>}
                  </SubNavigationItem>
                </Tooltip>
              </NodeBubble>
            </React.Fragment>
          ))
      }
    </SubNavigation>
  );
}
function JsonPageViewRenderer(page: { view: ComponentType<any>, className: string, tab: ITab }) {
  const { view: View, tab: { properties, path, component, ...tapProps }, className } = page;
  const { setTitleByView } = useTitle();
  const { $object } = useSource();
  useEffect(() => {
    setTitleByView($object, tapProps.title);
  }, []);
  return (
    <>
      {!!View && <View {...properties} {...tapProps} className={className}/>}
    </>
  );
}
function JsonPageView(page: { tabs: ITabs, layout: any }) {
  const { views } = useComponents();
  return (
    <Box container gap={"XXS"} flex={1} className={JsonPageClasses.JsonPageContent} direction={"column"}>
      <Routes>
        {page.tabs.map((tab, i) => (
          <Route key={tab.title + i} path={tab.route || tab.path} element={
            <JsonPageViewRenderer
              tab={tab}
              className={page.layout.className}
              view={views[ tab.component ]}
            />
          }/>
        ))}
        {!!page.tabs.length &&
        <Route path={"/*"} element={<Navigate to={page.tabs[ 0 ].path} replace/>}/>
        }
      </Routes>
    </Box>
  );
}

export type ElementInjections = Array<ElementInjection>;
export type ElementInjection = {
  component: string
  properties: Record<string, any>
  conditions?: TopLevelCondition
}
export type Stats = {
  missedCallsCount?: number
  unreadPipelinesCount?: number
  unreadMessagesCount?: number
  unreadEmailsCount?: number
  remindersCount?: number
  bulkEmailsCount?: number
  bulkSMSCount?: number
}
export type ITab = {
  path: string
  route?: string
  title: string
  icon: string
  navigation?: boolean;
  beta?: boolean;
  component: string
  jql?: {
    query?: {
      find?: any
      get?: any
    },
    mutation?: {
      create?: any,
      update?: any,
      delete?: any,

    }
    subscription?: any
  }
  properties?: Record<string, any>,
  conditions?: TopLevelCondition
}
export type ITabs = Array<ITab>
export interface Viewer {
  id;
  objectId;
  objectName;
  role;
  __typename;
}
export interface JsonPageProps {
  page: {
    className?: string | null;
    objectIcon?: string | null;
    objectName?: string | null;
    widgets: ElementInjections
    tabs: ITabs,
    dialog: Record<string, any>
    header?: ElementInjection
  };
  stats?: Stats;
  isFullScreen?: boolean;
}
export interface JsonViewProps {
  className: string;
  group?: string;
  title?: string;
  icon: IconType;
  classId?: string;
  filters?: { hide?: boolean, fields: any[], rules: any[] };
  fields?: JsonFieldProps[];
  sections?: JsonSectionProps[];
  headers?: Array<ListHeader & { properties?: string, conditions?: TopLevelCondition }>;
  blank?: ListViewBlank;
  draggable?: boolean;
  description?: string;
  permissions?: JsonPermissions;
  jql?: {
    query?: {
      find?: any
      get?: any
    },
    mutation?: {
      create?: any,
      update?: any,
      delete?: any
    },
    subscription: any
  };
  [ k: string ]: any;
}
export const enum JsonPageClasses {
  JsonPage = "json-page",
  JsonPageFull = "json-page-full",
  JsonPageContainer = "json-page-container",
  JsonPageContent = "json-page-content",
  NavigationWithIcon = "navigation-with-beta",
  BetaIcon = "beta-icon"
}
