import { ComponentMappings } from "@relcu/ui";

import { ContactDialog }            from "./Dialog/ContactDialogOld";
import { MailboxDialog }            from "./Dialog/MailboxDialog";
import { PhoneLineDialog }          from "./Dialog/PhoneLineDialog";
import { PriorityQueueDialog }      from "./Dialog/PriorityQueueDialog";
import { TaskDialog }               from "./Dialog/TaskDialog";
import { AddressField }             from "./Field/AddressField";
import { DeactivateAccountField }   from "./Field/DeactivateAccountField";
import { EmailsField }              from "./Field/EmailsField";
import { ImageUploadField }         from "./Field/ImageUploadFiled";
import { PhoneLinesField }          from "./Field/PhoneLinesField";
import { PhoneNumbersField }        from "./Field/PhoneNumbersField";
import { PointerField }             from "./Field/PointerField";
import { PointerListField }         from "./Field/PointerListField";
import { ProductCategoryField }     from "./Field/ProductCategoryField";
import { TagField }                 from "./Field/TagField";
import { TagsField }                from "./Field/TagsField";
import { LeadForm }                 from "./Form/LeadForm";
import { PhoneMessageTemplateForm } from "./Form/PhoneMessageTemplateForm";
import { ReferralPartnerForm }      from "./Form/ReferralPartnerForm";
import { RelcuLoanForm }            from "./Form/RelcuLoanForm";
import { UserForm }                 from "./Form/UserForm";
import { ContactHeader }            from "./Header/ContactHeader";
import { LeadFive9Header }          from "./Header/LeadFive9Header";

import { LeadHeader }                 from "./Header/LeadHeader";
import { ReferralHeader }             from "./Header/ReferralHeader";
import { ContactSection }             from "./Section/ContactSection";
import { LeadMemberSection }          from "./Section/LeadMemberSection";
import { EngagementAnalyticsSection } from "./Section/EngagementAnalyticsSection";
import { StatsSection }               from "./Section/LeadStatsSection";
import { MailAccountsSection }        from "./Section/MailAccountsSection/MailAccountsSection";
import NotificationControlsSection    from "./Section/NotificationControlsSection/NotificationControlsSection";
import { PasswordSection }            from "./Section/PasswordSection";
import { PermissionsSection }         from "./Section/PermissionsSection/PermissionsSection";
import { RelcuMilestoneSection }      from "./Section/PipelineSections/RelcuMilestoneSection";
import SoundSection                   from "./Section/SoundSection/SoundSection";
import StreetViewSection              from "./Section/StreetViewSection/StreetViewSection";
import { UserGeneralSection }         from "./Section/UserGeneralSection";

import { UserOBSection } from "./Section/UserOBSection";

import { VoiceMailSection }      from "./Section/VoiceMailSection";
import { VoiceOutOfWorkSection } from "./Section/VoiceOutOfWorkSection";
import { VoiceTemplateSection }  from "./Section/VoiceTemplateSection";

import { ActivityView }   from "./View/ActivityView";
import { AnalyticsView }  from "./View/AnalyticsView";
import { DashboardsView } from "./View/DashboardsView";

import AppraisalListView            from "./View/AppraisalListView/AppraisalListView";
import { AutomationView }           from "./View/AutomationView";
import { ConferenceView }           from "./View/ConferenceView";
import { ConversationView }         from "./View/ConversationView/ConversationView";
import { DetailsView }              from "./View/DetailsView";
import DistributionQueueView        from "./View/DistributionQueueView";
import { EmailSignatureView }       from "./View/EmailSIgnatureView";
import { FlyerGenerationView }      from "./View/FlyerGenerationView";
import { FocusView }                from "./View/FocusView";
import { LeadImportView }           from "./View/LeadImportView";
import { LeadView }                 from "./View/LeadView";
import { ListView }                 from "./View/ListView";
import { LoanProposalTemplateView } from "./View/LoanProposalTemplateView";
import { LoanProposalView }         from "./View/LoanProposalView";
import { LoanEstimateView }         from "./View/LoanEstimateView";
import { MailTemplateView }         from "./View/MailTemplateView";
import { MailView }                 from "./View/MailView";
import { MailIntegrationView }      from "./View/MailIntegrationView/MailIntegrationView";
import { MessageView }              from "./View/MessageView";
import { MoomLoaChecklistView }     from "./View/MoomLoaChecklistView";
import { RelcuLoanView }            from "./View/RelcuLoanView";
import { NotesView }                from "./View/NotesView";
import { ObjectView }               from "./View/ObjectView/ObjectView";
import { PreferenceView }           from "./View/PreferanceView";
import { PricingBetaView }          from "./View/PricingBetaView";
import { PricingView }              from "./View/PricingView";
import { PriorityView }             from "./View/PriorityView";
import { SchemaView }             from "./View/SchemaView";
import { ReminderListView }         from "./View/ReminderListView";
import { PhoneMessageTemplateView } from "./View/PhoneMessageTemplateView";
import { TableView }                from "./View/TableView";
import TimelineView                 from "./View/TimelineView";
import { UserDetailsView }          from "./View/UserDetailsView";
import { ActivityWidget }           from "./Widget/ActivityWidget";
import { LeadCustomWidget }         from "./Widget/LeadCustomWidget";
import { LeadWidget }               from "./Widget/LeadWidget";
import { MoomLeadWidget }           from "./Widget/MoomLeadWidget";
import { NoteWidget }               from "./Widget/NoteWidget";
import { TagWidget }                from "./Widget/TagWidget";
import { TimezoneWidget }           from "./Widget/TimezoneWidget";

export const components: ComponentMappings = {
  fields: {
    PointerField,
    PointerListField,
    EmailsField,
    PhoneNumbersField,
    PhoneLinesField,
    ImageUploadField,
    ProductCategoryField,
    TagField,
    TagsField,
    AddressField,
    DeactivateAccountField
  },
  headers: {
    LeadHeader,
    ReferralHeader,
    ContactHeader,
    LeadFive9Header
  },
  sections: {
    StatsSection,
    EngagementAnalyticsSection,
    PasswordSection,
    VoiceMailSection,
    VoiceTemplateSection,
    VoiceOutOfWorkSection,
    MailAccountsSection,
    SoundSection,
    NotificationControlsSection,
    StreetViewSection,
    LeadMemberSection,
    ContactSection,
    UserGeneralSection,
    UserOBSection,
    PermissionsSection,
    RelcuMilestoneSection
    // VoiceTemp
    // lateSection
  },
  views: {
    FocusView,
    ListView,
    TableView,
    AutomationView,
    AnalyticsView,
    DashboardsView,
    PriorityView,
    RelcuLoanView,
    LoanProposalTemplateView,
    PreferenceView,
    ConversationView,
    MailView,
    EmailSignatureView,
    DetailsView,
    TimelineView,
    NotesView,
    MoomLoaChecklistView,
    ActivityView,
    ObjectView,
    ConferenceView,
    ReminderListView,
    LoanProposalView,
    LoanEstimateView,
    UserDetailsView,
    MessageView,
    PricingView,
    PricingBetaView,
    MailTemplateView,
    PhoneMessageTemplateView,
    LeadView,
    DistributionQueueView,
    MailIntegrationView,
    LeadImportView,
    AppraisalListView,
    FlyerGenerationView,
    SchemaView
  },
  forms: {
    LeadForm,
    ReferralPartnerForm,
    UserForm,
    RelcuLoanForm,
    PhoneMessageTemplateForm
  },
  dialogs: {
    ContactDialog,
    PhoneLineDialog,
    MailboxDialog,
    TaskDialog,
    PriorityQueueDialog
  },
  widgets: {
    ActivityWidget,
    MoomLeadWidget,
    LeadCustomWidget,
    LeadWidget,
    NoteWidget,
    TimezoneWidget,
    TagWidget
  }
};
