import React                                                 from "react";
import { FC }                                                from "react";
import { FieldCell }                                         from "@relcu/rc";
import { FormWhere }                                         from "@relcu/ui";
import { useBetaLoanLimit }                                  from "../../useBetaLoanLimit";
import { WHEN_TOTAL_LOAN_AMOUNT_EXCEEDS_AVERAGE_LOAN_LIMIT } from "../offer.conditions";
import { NullValueCell }                                     from "./NullValueCell";

export const TotalLoanAmountCell: FC<any> = React.memo(function TotalLoanAmountCell(props) {
  const limit = useBetaLoanLimit();

  return <FormWhere
    conditions={WHEN_TOTAL_LOAN_AMOUNT_EXCEEDS_AVERAGE_LOAN_LIMIT}
    source={{
      $limit: limit
    }}
    nullValue={<NullValueCell/>}>
    {
      ({ apply }) => (
        <FieldCell.Number
          status={apply ? "warning" : null}
          name={"totalLoanAmount"} plaintext icon={"functions"}
          helperText={apply ? "Loan amount exceeds maximum loan limit." : "Value can not be equal to zero or empty"}
          precision={2}
        />
      )
    }
  </FormWhere>;
});
