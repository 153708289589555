import React                       from "react";
import { useContext }              from "react";
import { omit }                    from "@relcu/ui";
import { useApolloClient }         from "@apollo/client";
import { Button }                  from "@relcu/rc";
import { Icon }                    from "@relcu/rc";
import { LOAN_ESTIMATE }           from "../../../../graph/operations.graphql";
import { Proposal }                from "./Proposal";
import { useAppendObCustomFields } from "./useDefaultOffer";
import { useDefaultOffer }         from "./useDefaultOffer";
import { createDefaultOfferEdge }  from "./utils";

export const AddOffer = React.memo(function AddOffer() {
  const { data } = useDefaultOffer();
  const client = useApolloClient();
  const loanEstimate = useContext(Proposal.Context);
  const onClick = () => {
    client.writeFragment({
      fragment: useAppendObCustomFields(LOAN_ESTIMATE),
      fragmentName: "LoanEstimate",
      data: {
        ...loanEstimate,
        offers: {
          ...loanEstimate.offers,
          edges: [
            ...loanEstimate.offers.edges,
            createDefaultOfferEdge({ ...omit(data, ["rate", "apr", "price"]), objectName: `Offer ${loanEstimate.offers.edges.length + 1}` })
          ]
        }
      },
      id: client.cache.identify({ __typename: loanEstimate.__typename, id: loanEstimate.id })
    });
  };
  return <Button disabled={loanEstimate.offers.edges.length >= 3} onClick={onClick} appearance={"text"}
                 startIcon={<Icon type={"add"}/>}>ADD OFFER</Button>;
});
