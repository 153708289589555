import { useMemo }           from "react";
import React                 from "react";
import { FC }                from "react";
import { AvatarColors }      from "../../..";
import { AvatarSizes }       from "../../..";
import { Avatar }            from "../../..";
import { Box }               from "../../..";
import { Label }             from "../../..";
import { classNames }        from "../../..";
import { BoxComponentProps } from "../../..";
import { applyBoxStyles }    from "../../..";
import { BaseInputClasses }  from "../BaseInput";
import { BaseInputProps }    from "../BaseInput";
import { InputState }        from "../Input";
import { useImageUpload }    from "./useImageUpload";

export interface ImageUploadProps extends BoxComponentProps, BaseInputProps {
  state?: InputState
  halfSize?: boolean
  message?: string
  value: string
  acceptedTypes?: string[]
  onUpload(file: File | string)
  onChange?(e?)
  onBlur?(e?)
  onFocus?(e?)
}
const defaultImageUploadProps: Partial<ImageUploadProps> = {
  disabled: false,
  onChange: null,
  halfSize: false,
  container: true,
  direction: "column",
  alignItems: "baseline"
};

export const ImageUpload: FC<ImageUploadProps> = React.memo(function ImageUpload(props) {
  let properties = applyBoxStyles<ImageUploadProps>(props);
  const { className, label, placeholder, disabled, halfSize, fullSize, message, required, value, onUpload, acceptedTypes, ...p } = properties;
  const classes = classNames(ImageUploadClasses.ImageUpload, className, BaseInputClasses.Input);
  const { handleOpenModal, handleFieldClear } = useImageUpload(properties);
  const addLabel = useMemo(() => {
    if (label) {
      return `ADD ${label.toUpperCase()}`;
    }
    return "ADD PROFILE PICTURE";
  }, [label]);
  return (
    <Box container className={classes} alignItems={"stretch"} {...p} gap={"XXXS"} direction={"row"}>
      <Box container
           direction={"row"}
           alignItems={"center"}
           gap={"XXS"}
           flexGrow={1}
           className={ImageUploadClasses.ImageContainer}>
        <Box container direction={"column"} gap={"XXXS"} flex={1}>
          {label && <Label required={required}>{label}</Label>}
          <Box container alignItems={"center"} justify={"space-between"}>
            <Box container gap={"XXS"} alignItems={"center"} style={{ overflow: "hidden" }}>
              <Avatar icon={(value) || "N/A"} alignSelf={"start"} size={AvatarSizes.Small}
                      color={!value && AvatarColors.Primary}/>
              {
                value &&
                <div className={classNames(ImageUploadClasses.ImageButton, ImageUploadClasses.ImageButtonError)}
                     role={"button"}
                     onClick={handleFieldClear}>{"DELETE"}</div>
              }
              <div className={ImageUploadClasses.ImageButton} role={"button"}
                   onClick={handleOpenModal}>{value ? "CHANGE" : addLabel}</div>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box container className={BaseInputClasses.Message}>
        {message}
      </Box>
    </Box>
  );
});
ImageUpload.defaultProps = defaultImageUploadProps;

//todo check why button click works on enter

export enum ImageUploadClasses {
  ImageUpload = "image-upload",
  ImageContainer = "image-upload__container",
  ImageButton = "image-upload__button",
  ImageButtonError = "image-upload__button--error",
}