import React           from "react";
import { FC }          from "react";
import { useContext }  from "react";
import { useEffect }   from "react";
import EmailEditor     from "react-email-editor";
import { useField }    from "@relcu/form";
import { useForm }     from "@relcu/form";
import { HiddenField } from "@relcu/ui";
import { useAlert }    from "@relcu/ui";
import { useSource }   from "@relcu/ui";
import { fileToBase64 } from "../../../../../utils/helpers";
import { FlyerContext } from "./Content";
import { FlyerPreview } from "./FlyerPreview";

export interface FlyerEditorProps {
  onEditorLoad?(loading: boolean);
}
export const FlyerEditor: FC<FlyerEditorProps> = React.memo(function FlyerEditor(props) {
  const { preview, emailEditorRef, emailEditorReplacementParams } = useContext(FlyerContext);
  const { error } = useAlert();
  const form = useForm();
  const contentField = useField("content");

  const { $viewer } = useSource();
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(fileToBase64(reader));
    reader.onerror = reject;
  });

  const onLoad = (unlyer) => {
    unlyer.registerCallback("image", async function (file, done) {
      const data = new FormData();
      const attachment = file.attachments[ 0 ];
      const base64File: any = await toBase64(attachment);
      data.append("data", JSON.stringify({
        content: {
          base64: base64File
        },
        name: attachment.name,
        type: attachment.type
      }));

      fetch("/api/v1/templateFiles", {
        method: "POST",
        headers: {
          "Accept": "application/json"
        },
        body: data
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          error("Oops something went wrong");
          throw new Error("Oops something went wrong");
        }
      }).then(response => {
        return response.json();
      }).then(data => {
        // Pass the URL back to Unlayer to mark this upload as completed
        done({ progress: 100, url: data.url });
      });
    });

    const values = form.getState().values;
    if (values.jsonContent) {
      unlyer.loadDesign(values.jsonContent);
    }
  };

  const onReady = (unlyer) => {
    // editor is ready
    props.onEditorLoad(false);
    unlyer?.addEventListener("design:updated", function (data) {
      unlyer.exportHtml((data) => {
        const { design, html } = data;
        contentField.input.onChange(html); //todo do not change this it need to affect on dirty field
        form.change("jsonContent", design);
      });
    });
  };
  useEffect(() => {
    props.onEditorLoad(true);
  }, []);

  return <>
    <HiddenField name={"title"}/>
    <HiddenField name={"availableFor"}/>
    <HiddenField name={"enabled"}/>
    {
      preview &&
      <FlyerPreview/>
    }
    <div style={{ display: preview ? "none" : "flex", flex: 1 }}>
      <EmailEditor
        options={{
          user: {
            id: `${window.location.hostname}:${$viewer.objectId}`
          },
          id: "editor",
          displayMode: "document",
          projectId: window.__CONFIG__.unlayerProjectId,
          tools: {
            social: {
              enabled: true
            },
            timer: {
              enabled: true
            },
            video: {
              enabled: true
            }
          },
          features: {
            userUploads: true,
            imageEditor: {
              enabled: true
            }
          },
          mergeTags: emailEditorReplacementParams
        }}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
    </div>
  </>;
});

//todo first set merge tag

// import { useForm }                            from "@relcu/final-form";
// import React, { useRef, useEffect, useState } from "react";
// import { EmailEditor, EditorRef }             from "react-email-editor";
// import { EmailEditorProps }                   from "react-email-editor";
//
// export const FlyerEditor = () => {
//   // const form = useForm();
//   const emailEditorRef = useRef<EditorRef>(null);
//
//   const [mergeTags, setMergeTags] = useState({
//     first_name: "AJ"
//   });
//
//   useEffect(() => {
//     const savedDesign = localStorage.getItem("emailDesign_1");
//     console.info("LOAD DESIGN", !!savedDesign);
//     if (savedDesign) {
//       const unlayer = emailEditorRef.current?.editor;
//       unlayer?.loadDesign(JSON.parse(savedDesign));
//     }
//   }, []);
//
//   // useEffect(() => {
//   //   const values: any = form.getState().values;
//   //   if (values.jsonContent) {
//   //     const unlayer = emailEditorRef.current?.editor;
//   //     unlayer?.loadDesign(values.jsonContent);
//   //   }
//   // }, [form]);
//
//   const exportHtml = () => {
//     const unlayer = emailEditorRef.current?.editor;
//
//     unlayer?.exportHtml((data) => {
//       const { design, html } = data;
//       console.log("exportHtml", html);
//
//       const blob = new Blob([html], { type: "text/html" });
//
//       const link = document.createElement("a");
//
//       link.href = URL.createObjectURL(blob);
//
//       link.download = "exported_unlayer_html.html";
//
//       document.body.appendChild(link);
//
//       link.click();
//
//       document.body.removeChild(link);
//     });
//   };
//
//   const saveDesign = () => {
//     const unlayer = emailEditorRef.current?.editor;
//
//     unlayer?.exportHtml((data) => {
//       const { design } = data;
//
//       if (design) {
//         localStorage.setItem("emailDesign_1", JSON.stringify(design));
//         console.log("Design saved successfully");
//         alert("Design saved");
//       }
//     });
//   };
//
//   const handleUpdateMergeTag = () => {
//     setMergeTags((prevTags) => ({
//       ...prevTags,
//       first_name: "Chris"
//     }));
//   };
//
//   const onReady: EmailEditorProps["onReady"] = (unlayer) => {
//     const savedDesign = localStorage.getItem("emailDesign_1");
//     if (savedDesign) {
//       const unlayer = emailEditorRef.current?.editor;
//       unlayer?.loadDesign(JSON.parse(savedDesign));
//     }
//
//     unlayer?.addEventListener("design:updated", function (data) {
//       unlayer.exportHtml((data) => {
//         const { design, html } = data;
//         if (design) {
//           console.log("Design:updated successfully", html);
//           localStorage.setItem("emailDesign_1", JSON.stringify(design));
//         }
//       });
//     });
//
//     unlayer.registerCallback("previewHtml", function (params, done) {
//       console.log("Preview callback registered");
//       unlayer.exportHtml(function (data) {
//         const { html } = data;
//         done({
//           html: html
//         });
//         console.log("done", html);
//       });
//     });
//   };
//
//   return (
//     <div>
//       <div style={{ marginBottom: "10px" }}>
//         <button onClick={exportHtml} style={{ padding: "10px", fontSize: "16px", fontWeight: "bold" }}>
//           Export HTML
//         </button>
//         <button onClick={saveDesign} style={{ padding: "10px", fontSize: "16px", fontWeight: "bold" }}>
//           Save
//         </button>
//         <button onClick={handleUpdateMergeTag} style={{ padding: "10px", fontSize: "16px", fontWeight: "bold" }}>
//           Update Merge Tag (First Name to Chris)
//         </button>
//       </div>
//
//       <EmailEditor
//         ref={emailEditorRef}
//         onReady={onReady}
//         style={{
//           backgroundColor: "#f5f5f5"
//         }}
//         options={{
//           projectId: window.__CONFIG__.unlayerProjectId,
//           displayMode: "document",
//           mergeTags: {
//             first_name: {
//               name: "First Name",
//               value: mergeTags.first_name,
//               sample: "John"
//             }
//           }
//         }}
//       />
//     </div>
//   );
// };
//
// import { useState }         from "react";
// //second merge tags
// import React                from "react";
// import { FC }               from "react";
// import { useRef }           from "react";
// import { EmailEditorProps } from "react-email-editor";
// import { EditorRef }        from "react-email-editor/dist/types";
// import EmailEditor          from "react-email-editor";
//
// export interface FlyerEditorProps {
//   onEditorLoad?(loading: boolean);
// }
// export const FlyerEditor: FC<FlyerEditorProps> = React.memo(function FlyerEditor(props) {
//   const emailEditorRef = useRef<EditorRef>(null);
//   const [newValue, setNewValue] = useState(null)
//   const handleUpdateMergeTag = (e) => {
//     const newValue = e.target.value; // get the selected value from the dropdown
//     // const unlayer = emailEditorRef.current?.editor;
//     setNewValue(newValue)
//     // update the merge tag value based on the selected option
//   };
//
//   const onReady: EmailEditorProps["onReady"] = (unlayer) => {
//     const savedDesign = localStorage.getItem("emailDesign_1");
//     if (savedDesign) {
//       const unlayer = emailEditorRef.current?.editor;
//       unlayer?.loadDesign(JSON.parse(savedDesign));
//     }
//
//     unlayer?.addEventListener("design:updated", function (data) {
//       unlayer.exportHtml((data) => {
//         const { design, html } = data;
//         if (design) {
//           console.log("Design:updated successfully", html);
//           localStorage.setItem("emailDesign_1", JSON.stringify(design));
//         }
//       });
//     });
//
//     unlayer.registerCallback("previewHtml", function (params, done) {
//       // unlayer?.setMergeTags({
//       //   first_name: { name: "First Name", sample: newValue, value: newValue }
//       // });
//       unlayer.exportHtml(function (data) {
//         const { html } = data;
//         done({
//           html: html
//         });
//       });
//     });
//   };
//
//   return (
//     <div>
//       <div style={{ marginBottom: "10px" }}>
//         <select onChange={handleUpdateMergeTag}>
//           <option value="Admin">Value 1</option>
//           <option value="Lusine">Value 2</option>
//           <option value="Monica">Value 3</option>
//         </select>
//
//       </div>
//
//       <EmailEditor
//         onReady={onReady}
//         ref={emailEditorRef}
//         style={{
//           height: "70vh",
//           width: "60vw"
//         }}
//         options={{
//           projectId: window.__CONFIG__.unlayerProjectId,
//           displayMode: "document",
//           mergeTags: {
//             first_name: {
//               name: "First Name",
//               sample: "Sample Initial",
//               value: "Ara"
//             }
//           }
//         }}
//       />
//     </div>
//   );
// });


