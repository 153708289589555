import React                        from "react";
import { useCallback }              from "react";
import { formatNumber }             from "@relcu/ui";
import { useNumberUtils }           from "@relcu/ui";
import { RsRefForwardingComponent } from "../../@types/common";
import { UseInputNumberProps }      from "../../FormField/FormInputNumber";
import Icon                         from "../../Icon";
import { Tooltip }                  from "../../index";
import { Whisper }                  from "../../index";
import SelectPicker                 from "../../SelectPicker";
import { Cell }                     from "../../TableHorizontal";
import { useClassNames }            from "../../utils";
import { InputCellProps }           from "../InputCell";
import { SelectCellToggle }         from "../SelectCell/SelectCell";

export interface CurrencyCellProps extends InputCellProps {
  calculationUnit?: "currency" | "percent";
  onCalculationUnitChange(unit: "currency" | "percent");
}

export const CurrencyCell: RsRefForwardingComponent<"div", CurrencyCellProps> = React.forwardRef((props: CurrencyCellProps, ref) => {
  const { calculationUnit, onCalculationUnitChange } = props;

  const onChange = useCallback((event) => {
    let cursor = event.target.selectionEnd;
    props.onChange(event);
    event.persist();
    setTimeout(() => {
      if (String(event.target.value).includes(",")) {
        const oldLength = (String(props.value).match(/,/g) || []).length;
        const newLength = (String(event.target.value).match(/,/g) || []).length;
        if (oldLength !== newLength) {
          cursor += 1;
        }
      }
      event.target.selectionStart = cursor;
      event.target.selectionEnd = cursor;
      event.target.focus();
    });
  }, [props.onChange, props.value]);
  const { prefix, withClassPrefix } = useClassNames("currency-cell-select", "rc");
  return <Cell
    className={"rc-currency-cell"}
    status={props.status}
    affected={props.affected}
    space={props.space}
    onAnimationEnd={props.onAnimationEnd}
    revers={props.revers}
    style={props.style}
    inert={props.inert}
  >
    <SelectPicker
      onChange={onCalculationUnitChange}
      value={calculationUnit}
      className={withClassPrefix()}
      data={[
        {
          label: "",
          value: "currency",
          icon: "attach_money"
        },
        {
          label: "",
          value: "percent",
          icon: "rc_percent"
        }
      ]}
      renderValue={(value, item) => {
        return (
          <Icon
            type={item[ "icon" ]}
            style={{
              color: "var(--rc-text-tertiary)",
              fontSize: 16,
              fontWeight: 400,
              lineHeight: 16
            }} className={prefix("status-icon")}/>
        );
      }}
      renderMenuItem={(label, item) => (
        <Icon
          type={item.icon}
          style={{
            color: calculationUnit == item.value ? "var(--rc-accent-03-primary)" : "var(--rc-text-tertiary)",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 16
          }} className={prefix("status-icon")}/>
      )}
      placement={"autoVertical"}
      menuClassName={prefix("menu")}
      toggleAs={SelectCellToggle}
      containerPadding={0}
      // style={{
      //   border: "none !important",
      //   borderRadius: "0 !important",
      //   background: "var(--rc-surface-primary) !important"
      // }}
      searchable={false}
    />
    {
      ((props.status == "error" || props.status == "warning") &&
        (
          props.helperText ?
            <Whisper placement="top" speaker={<Tooltip>{props.helperText}</Tooltip>}>
              <Icon type={(props.status == "error" || props.status == "warning") ? "error" : null}
                    className={"rc-cell-status-icon"}/>
            </Whisper> :
            <Icon type={(props.status == "error" || props.status == "warning") ? "error" : null}
                  className={"rc-cell-status-icon"}/>
        )
      )
    }
    <input onChange={onChange} value={props.value} style={{ width: "100%" }}/>
  </Cell>;
});

export const useCurrencyNumber = (props: UseInputNumberProps & {
  dependedFieldValue?: string;
  calculationUnit?: "currency" | "percent";
  previousCalculationUnit?: "currency" | "percent";
}) => {
  const { precision = 2, calculationUnit, previousCalculationUnit, dependedFieldValue, ...config } = props;
  const { clean, parse: parseUtil, toFixedNoRounding } = useNumberUtils(config);
  const parse = (value, name) => {
    const str = String(value);
    if (value && precision && str.indexOf(".") < 0) {
      const end = str.substring(str.length - precision, str.length);
      const start = str.substring(0, str.length - precision);
      if (start && end) {
        value = [start, end].join(".");
      }
    }
    value = clean(value);
    if (!value) {
      return parseUtil(null, name);
    }
    if (precision || precision === 0) {
      value = toFixedNoRounding(value, calculationUnit == "percent" ? 3 : precision);
    }

    const parsedNumber = parseUtil(Number(value), name);
    const number = isNaN(parsedNumber) ? 0 : parsedNumber;

    if (calculationUnit == "percent") {
      const percentNumber = (Number(dependedFieldValue) * number) / 100;
      return isNaN(percentNumber) ? 0 : percentNumber;
    }

    return number;
  };
  const format = (value, name) => {
    value = clean(value);
    if (!value) {
      return null;
    }
    const number = formatNumber(value, precision);

    if (calculationUnit == "percent") {
      return formatNumber(percentage(value, dependedFieldValue), 3);
    }

    return number;
  };

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  return { parse, format };
};
