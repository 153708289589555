import { useMutation }              from "@apollo/client";
import { useNavigate }              from "@relcu/react-router";
import { addMinutes }               from "@relcu/ui";
import { useModal }                 from "@relcu/ui";
import { deviceVar }                from "../reactiveVars";
import { DeviceCallParams }         from "../Tw";
import { getPhoneNumberForCall }    from "../utils/helpers";
import { toNodeId }                 from "../utils/helpers";
import { getObjectPath }            from "../utils/layoutUtils";
import { MarkCallsAsReadVariables } from "./__types__/MarkCallsAsRead";
import { MarkCallsAsRead }          from "./__types__/MarkCallsAsRead";
import { SchemaDialog }             from "./Layout/Dialog/SchemaDialog";
import { MARK_CALLS_AS_READ }       from "./operations.graphql";

type ITask = { subject?: { __typename: string, objectId: string } };
export function useTask() {
  const navigate = useNavigate();
  const [markAsRead] = useMutation<MarkCallsAsRead, MarkCallsAsReadVariables>(MARK_CALLS_AS_READ);
  const [schemaModal, contextSchemaHolder] = useModal(SchemaDialog);
  return {
    contextSchemaHolder,
    async onCallBack({ e, task, fromNumbers }) {
      e.stopPropagation();
      if (fromNumbers.length == 0) {
        return;
      }
      if (!task.subject) {
        return;
      }
      if (task.subject.contact.phones.length == 0) {
        return;
      }
      const defaultNumber = getPhoneNumberForCall(task.subject.contact.phones);
      const callable = !defaultNumber?.callOptOut && defaultNumber?.number;
      const { call } = deviceVar();
      if (callable) {
        const params: DeviceCallParams = {
          from: fromNumbers[ 0 ].value,
          to: callable,
          contactName: task.subject.contact.objectName,
          contactId: task.subject.contact.objectId,
          markAsRead: async () => {
            await markAsRead({
              variables: {
                scope: toNodeId(task.subject)
              }
            });
          }
        };
        if (task.subject) {
          params.scopeId = task.subject.objectId;
          params.scopeName = task.subject.objectName;
          params.scopeClassName = task.subject.__typename;
        }
        const calling = await call(params);
        if (calling) {
          navigate(getObjectPath(task.subject));
        }
      }
    },
    onEdit(e, task: ITask) {
      e.stopPropagation();
      schemaModal({
        className: "Task",
        action: "save",
        record: {
          ...task
        }
      });
    },
    onSendSms(e, task: ITask) {
      e.stopPropagation();
      if (!task.subject) {
        return;
      }
      return navigate(`${getObjectPath(task.subject)}/sms`);
    },
    onNavigate(task: ITask) {
      if (!task.subject) {
        return;
      }
      return navigate(getObjectPath(task.subject));
    },
    async onSnooze({ task, number, update }) {
      const currentTime = new Date();
      const dueDate = (currentTime < new Date(task.dueDate)) ? addMinutes(new Date(task.dueDate), Number(number)) : addMinutes(currentTime, Number(number));
      await update({
        variables: {
          id: task.id,
          dueDate
        }
      });
    }
  };
}
