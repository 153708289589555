import { useField }              from "@relcu/form";
import { UseFieldConfig }        from "@relcu/form";
import { ReactNode }             from "react";
import { ReactElement }          from "react";
import { useMemo }               from "react";
import React, { useContext }     from "react";
import { FormControlBaseProps }  from "../@types/common";
import FormContext               from "../Form/FormContext";
import { IconType }              from "../Icon";
import { InputProps }            from "../index";
import { Icon }                  from "../index";
import { InputGroup }            from "../index";
import { Form }                  from "../index";
import Input                     from "../Input";
import { createChainedFunction } from "../utils";
import { composeValidators }     from "../utils/composeValidators";

export interface FormFieldProps<P = any, ValueType = any> extends UseFieldConfig<ValueType> {
  name: string;
  type?: string;
  plaintext?: boolean;
  errorMessage?: boolean;
  helperText?: string;
  prefix?: string;
  label?: string;
  icon?: IconType;
  required?: boolean;
  component?: React.ElementType<P & FormControlBaseProps<ValueType>>;
  addon?: ReactNode;
  properties?: Partial<P>;
  groupProperties?: Partial<P>;
}

export interface FormFieldComponent<P = any> extends React.FC<FormFieldProps<P>> {
  <P = any>(
    props: FormFieldProps<P>
  ): React.ReactElement | null;
}

export const createRequireValidator = label => (value) => (value || value == 0 ? undefined : `${label || "Field"} is required.`);
export const createNullableValidator = label => value => (!(value === null || value === undefined) ? undefined : `${label || "Field"} is required.`);

export const FormField: FormFieldComponent = React.memo((props: FormFieldProps) => {
  const {
    name,
    label,
    icon,
    component,
    addon,
    validate,
    plaintext,
    helperText,
    properties,
    groupProperties,
    prefix,
    required,
    errorMessage = true,
    ...config
  } = props;
  const formContext = useContext(FormContext);
  const requiredValidator = useMemo(() => required && createRequireValidator(label), [label, required]);
  const field = useField(name, {
    ...config,
    validate: useMemo(() => composeValidators(requiredValidator, validate), [requiredValidator, validate])
  });
  const hasError = field.meta.error && field.meta.touched;
  return (
    <Form.Group controlId={name} {...groupProperties}>
      {!!label && <Form.ControlLabel>{label}</Form.ControlLabel>}
      {formContext?.layout !== "inline" ?
        <InputGroup inside size={"md"}>
          {!!icon &&
            <InputGroup.Addon>
              <Icon type={icon}/>
            </InputGroup.Addon>
          }
          <Form.Control
            {...properties}
            {...field.input}
            accepter={component}
            name={name}
            prefix={prefix}
            color={hasError ? "error" : properties?.color}
          />
          {
            hasError && !addon &&
            <InputGroup.Addon>
              <Icon color={"error"} type={"error"}/>
            </InputGroup.Addon>
          }
          {addon}
        </InputGroup> :
        <Form.Control
          {...properties}
          {...field.input}
          accepter={component}
          name={name}
          prefix={prefix}
          color={hasError ? "error" : properties?.color}
        />
      }
      {!!hasError && !!errorMessage && <Form.HelpText color={"error"}>{field.meta.error}</Form.HelpText>}
      {!hasError && !!helperText && <Form.HelpText>{helperText}</Form.HelpText>}
    </Form.Group>
  );
});

FormField.displayName = "FormField";
export default FormField;
