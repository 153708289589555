import React                           from "react";
import { useCallback }                 from "react";
import { useState }                    from "react";
import { useContext }                  from "react";
import { useMemo }                     from "react";
import { useReactiveVar }              from "@apollo/client";
import { useApolloClient }             from "@apollo/client";
import { useMutation }                 from "@apollo/client";
import { gql }                         from "@apollo/client";
import { FormApi }                     from "@relcu/final-form";
import { FormSpy }                     from "@relcu/final-form";
import { getIn }                       from "@relcu/final-form";
import { EmptyModal }                  from "@relcu/rc";
import { FlexboxGrid }                 from "@relcu/rc";
import { Button }                      from "@relcu/rc";
import { Checkbox }                    from "@relcu/rc";
import { Form }                        from "@relcu/rc";
import { omit }                        from "@relcu/ui";
import { useModal }                    from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { LOAN_ESTIMATE }               from "../../../../../graph/operations.graphql";
import { layoutVar }                   from "../../../../../reactiveVars";
import { useJqlMutation }              from "../../../Jql";
import { useAppendObCustomFields }     from "../useDefaultOffer";
import { CreateLoanEstimate }          from "./__types__/CreateLoanEstimate";
import { CreateLoanEstimateVariables } from "./__types__/CreateLoanEstimate";
import { DeleteLoanEstimateVariables } from "./__types__/DeleteLoanEstimate";
import { DeleteLoanEstimate }          from "./__types__/DeleteLoanEstimate";
import { GetLoanEstimateVariables }    from "./__types__/GetLoanEstimate";
import { GetLoanEstimate }             from "./__types__/GetLoanEstimate";
import { ProposalAddress }             from "./ProposalAddress";
import { ProposalLoanPurpose }         from "./ProposalLoanPurpose";
import { ProposalOccupancy }           from "./ProposalOccupancy";
import { ProposalPropertyType }        from "./ProposalPropertyType";
import { GET_LOAN_ESTIMATE }           from "./ProposalProvider";
import { ProposalContext }             from "./ProposalProvider";
import { ProposalProvider }            from "./ProposalProvider";

export interface ProposalComponent extends React.FC {
  PropertyType: typeof ProposalPropertyType;
  Occupancy: typeof ProposalOccupancy;
  Address: typeof ProposalAddress;
  LoanPurpose: typeof ProposalLoanPurpose;
  Provider: typeof ProposalProvider;
  Context: typeof ProposalContext;
  Consumer: typeof ProposalContext["Consumer"];
}

const CREATE_LOAN_ESTIMATE = gql`
  mutation CreateLoanEstimate($fields:CreateLoanEstimateFieldsInput!){
    createLoanEstimate(input: {fields:$fields}){
      loanEstimate {
        ...LoanEstimate
      }
    }
  }
  ${LOAN_ESTIMATE}
`;
const DELETE_LOAN_ESTIMATE = gql`
  mutation DeleteLoanEstimate($id:ID!){
    updateLoanEstimate(input: {id:$id fields:{deleted: true}}){
      loanEstimate {
        ...LoanEstimate
      }
    }
  }
  ${LOAN_ESTIMATE}
`;

export const Proposal: ProposalComponent = (props) => {
  const { $object: lead, $viewer } = useSource();
  const client = useApolloClient();
  const [applyChanges, setApplyChanges] = useState(false);
  const context = useContext(ProposalContext);
  const [confirm, contextHolder] = useModal(EmptyModal);
  const loanEstimateId = context?.id;
  const layouts = useReactiveVar(layoutVar);
  const [createLoanEstimate] = useMutation<CreateLoanEstimate, CreateLoanEstimateVariables>(useAppendObCustomFields(CREATE_LOAN_ESTIMATE));
  const [deleteLoanEstimate] = useMutation<DeleteLoanEstimate, DeleteLoanEstimateVariables>(useAppendObCustomFields(DELETE_LOAN_ESTIMATE));
  const [updateLead] = useJqlMutation(layouts.Lead.jql.mutation.update, {
    operationName: "UpdateLeadPricingBeta"
  });
  const initialValues = useMemo(() => {
    return {
      propertyOccupancy: getIn(context, "propertyOccupancy"),
      loanPurpose: getIn(context, "loanPurpose"),
      propertyType: getIn(context, "propertyType"),
      propertyState: getIn(context, "propertyState"),
      propertyCity: getIn(context, "propertyCity"),
      propertyCounty: getIn(context, "propertyCounty"),
      propertyZipCode: getIn(context, "propertyZipCode"),
      propertyFipsCode: getIn(context, "propertyFipsCode")
    };
  }, [context]);
  const onSubmit = useCallback(async (values, form: FormApi) => {
    const create = async () => {
      if (loanEstimateId) {
        await deleteLoanEstimate({ variables: { id: loanEstimateId } });
      }
      const { data: { createLoanEstimate: { loanEstimate } } } = await createLoanEstimate({
        variables: { fields: { ...values, lead: { link: lead.id }, owner: { link: $viewer.objectId } } }
      });
      if (applyChanges) {
        await updateLead({
          variables: {
            input: {
              id: lead.id,
              fields: {
                loanPurpose: loanEstimate.loanPurpose,
                property: {
                  ...omit(lead.property, ["__typename"]),
                  occupancy: loanEstimate.propertyOccupancy,
                  type: loanEstimate.propertyType,
                  propertyAddress: {
                    ...omit(lead.property?.propertyAddress, ["__typename"]),
                    state: loanEstimate.propertyState,
                    city: loanEstimate.propertyCity,
                    county: loanEstimate.propertyCounty,
                    zipCode: loanEstimate.propertyZipCode
                  }
                }
              }
            }
          }
        });
      }
      client.writeQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        data: {
          loanEstimates: {
            __typename: "LoanEstimateConnection",
            edges: [{ node: loanEstimate, __typename: "LoanEstimateEdge" }]
          }
        },
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true },
            deleted: { equalTo: false }
          }
        }
      });
    };
    if (loanEstimateId) {
      const { destroy } = confirm({
        header: "Changing the loan details",
        label: "CONTINUE",
        cancelLabel: "DISCARD CHANGES",
        title: `All previous offers will be reset.`,
        subtitle: "If you want to continue with new loan details, click the 'Continue' button.",
        async onSubmit() {
          await create();
          destroy();
        },
        async onCancel() {
          await form.reset();
          destroy();
        }
      });
    } else {
      await create();
    }
  }, [applyChanges, lead, loanEstimateId]);

  return (<div style={{ overflowY: "scroll", height: "100%" }}>
      <Form initialValues={initialValues} onSubmit={onSubmit} fluid>
        {props.children}
        {contextHolder}
        <Form.Group controlId="apply">
          <Form.Control value={applyChanges} onChange={value => setApplyChanges(!value)} accepter={Checkbox}
                        name="apply">Apply
            changes to the lead</Form.Control>
        </Form.Group>
        <FormSpy subscription={{ submitting: true, dirtySinceLastSubmit: true, dirty: true }}>
          {({ submitting, dirty, form }) => (<FlexboxGrid justify={"center"}>
              <Button type={"submit"}
                      size={"xs"}
                      disabled={submitting || (!dirty && !!loanEstimateId)}>{!loanEstimateId ? "START PROPOSAL" : "REGENERATE  PROPOSAL"}</Button>
            </FlexboxGrid>
          )}
        </FormSpy>
      </Form>
    </div>
  );
};
Proposal.PropertyType = ProposalPropertyType;
Proposal.Occupancy = ProposalOccupancy;
Proposal.Address = ProposalAddress;
Proposal.LoanPurpose = ProposalLoanPurpose;
Proposal.Provider = ProposalProvider;
Proposal.Context = ProposalContext;
Proposal.Consumer = ProposalContext.Consumer;
