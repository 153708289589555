import React         from "react";
import { FC }        from "react";
import { useEffect } from "react";
import { useForm }   from "@relcu/final-form";

export const MoreFeesEffector: FC<any> = React.memo(function MoreFeesEffector(props) {
  const form = useForm();
  useEffect(() => {
    const values = form.getState().values;
    // const optionalFieldNames = ["lockInFee", "sellerConcession", "earnestMoneyPaid", "condoCertificateFee"];

    if (!props.fees.includes("condoCertificateFee") && values.condoCertificateFee) {
      form.change("condoCertificateFee", null);
    }//todo not added other fees here cause can't reproduce this bug on them

    // optionalFieldNames.map(field => {
    //   console.info();
    //   if (!props.fees.includes("condoCertificateFee") && values.condoCertificateFee) {
    //     condoFeeInput.input.onChange(null)
    //   }
    // })

  }, [props.fees, form]);

  return null;
});
