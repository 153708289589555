import { TypePolicy } from "@apollo/client";

export const TableView: TypePolicy = {
  fields: {
    fields: {
      merge(existing, incoming) {
        return incoming ?? existing;
      }
    }
  }
};
