import { InputHTMLAttributes }      from "react";
import { SyntheticEvent }           from "react";
import { useMemo }                  from "react";
import React                        from "react";
import { WithAsProps }              from "../@types/common";
import { TypeAttributes }           from "../@types/common";
import { RsRefForwardingComponent } from "../@types/common";
import { IconType }                 from "../Icon";
import { Icon }                     from "../Icon";
import { Whisper }                  from "../index";
import { Tooltip }                  from "../index";
import { useClassNames }            from "../utils";

export interface CellProps extends WithAsProps, React.InputHTMLAttributes<HTMLInputElement> {
  /** A tag can have different colors */
  status?: TypeAttributes.Alert;

  span?: number;

  /**Tooltip text*/
  helperText?: string;

  /** Revers items order */
  revers?: boolean;

  /** Justify center */
  justify?: "end" | "center" | "start";

  icon?: IconType;

  /** space-between */
  space?: boolean;

  disabled?: boolean;

  onAnimationEnd?(event: SyntheticEvent);

  affected?: boolean;

  level?: number;

  inert?: boolean;
}

export const Cell: RsRefForwardingComponent<"div", CellProps> = React.forwardRef((props: CellProps, ref) => {
  const {
    as: Component = "div",
    className,
    children,
    status,
    helperText,
    icon,
    classPrefix = "cell",
    revers,
    span,
    space,
    style,
    justify,
    affected,
    disabled,
    onAnimationEnd,
    level,
    inert,
    ...rest
  } = props;

  const { withClassPrefix, prefix, merge } = useClassNames(classPrefix, "rc");
  const classes = merge(
    className,
    withClassPrefix(status, { revers, space, justify, affected, disabled })
  );

  let styles = useMemo(() => {
    let styles: any = style;

    if (level) {
      styles = { ...styles, "--rc-padding-level": level };
    }

    if (justify) {
      styles = { ...styles, justifyContent: justify };
    }

    if (span) {
      styles = {
        ...styles,
        height: span * 40
      };
    }

    return styles;
  }, [style, span, justify, level]);

  return <Component
    className={classes}
    style={styles}
    onAnimationEnd={onAnimationEnd}
    inert={inert ? "" : undefined}
    {...rest}>
    {
      (helperText || icon) &&
      (
        helperText ?
          <Whisper placement="top" speaker={<Tooltip>{helperText}</Tooltip>}>
            <Icon type={(status == "error" || status == "warning" || status == "info") ? "error" : icon} className={prefix("status-icon")}/>
          </Whisper> :
          <Icon type={(status == "error" || status == "warning") ? "error" : icon} className={prefix("status-icon")}/>
      )
    }
    {children}
  </Component>;
});
