import { useReactiveVar }    from "@apollo/client";
import React                 from "react";
import { FC }                from "react";
import { useCallback }       from "react";
import { useQuery }          from "@apollo/client";
import { useParams }         from "@relcu/react-router";
import { JsonPage }          from "@relcu/ui";
import { SourceProvider }    from "@relcu/ui";
import { GET_SETTINGS }      from "../../../graph/operations.graphql";
import { VIEWER_USER_NODE }  from "../../../graph/operations.graphql";
import { fullScreenVar }     from "../../../reactiveVars";
import { SubscriptionEvent } from "../../../types/graphql-global-types";
import { ILayout }           from "../../../types/ILayouts";
import { toNodeId }          from "../../../utils/helpers";
import { useStat }           from "../../Stat";
import { JqlSubscription }   from "../Jql";
import { JqlQuery }          from "../Jql";

export const Page: FC<{ layout: ILayout }> = React.memo(function Page(props) {
  const { data: { viewer: { user: $viewer } = { user: {} } } = {} } = useQuery(VIEWER_USER_NODE, { fetchPolicy: "cache-only" });
  const isFullScreen = useReactiveVar(fullScreenVar)
  const { data: { settings: $settings } } = useQuery(GET_SETTINGS, { fetchPolicy: "cache-only" });
  const pageProps = { ...props, $viewer, $settings };

  if (props.layout.jql.query.get && !props.layout.page) {
    return <EntityPage {...pageProps} isFullScreen={isFullScreen}/>;
  }
  return <LayoutPage  {...pageProps} isFullScreen={isFullScreen}/>;
});
export const LayoutPage: FC<{ layout: ILayout, $viewer: any, $settings: any, isFullScreen?: boolean }> = React.memo(function LayoutPage(props) {
  const { layout, $viewer, $settings, ...rest } = props;
  const defaultObject = {
    objectName: props.layout.objectName,
    objectIcon: props.layout.objectIcon
  };

  return (
    <JqlQuery
      query={props.layout.jql.query.get || { operation: undefined }}
      skip={!props.layout.jql.query.get}
      operationName="LayoutPageNode"
      fetchPolicy="cache-first"
      nextFetchPolicy="cache-only"
    >
      {({ data }) => {
        const $object = data?.[ props.layout.jql.query.get?.operation ] ?? {};
        return (!!data || !props.layout.jql.query.get) &&
          <SourceProvider source={{ $object: { ...defaultObject, ...$object }, $viewer, $settings }}>
            <JsonPage
              page={layout}
              {...rest}
            />
            {/*{!!props.layout.jql.subscription &&*/}
            {/*<JqlSubscription*/}
            {/*  subscription={props.layout.jql.subscription}*/}
            {/*  variables={{ events: [SubscriptionEvent.UPDATE] }}*/}
            {/*/>*/}
            {/*}*/}
          </SourceProvider>;
      }}
    </JqlQuery>
  );
});
export const EntityPage: FC<{ layout: ILayout, $viewer: any, $settings: any, isFullScreen?: boolean }> = React.memo(function EntityPage(props) {
  const { layout, $settings, $viewer, ...rest } = props;
  const { objectId } = useParams();
  const id = props.layout.className && toNodeId({ objectId, className: props.layout.className });
  const stats = useStat($viewer.id !== id && id);
  const onData = useCallback(() => {
    console.info(`${props.layout.className} has been updated!`);
  }, [props.layout.className]);

  return (
    <JqlQuery
      query={
        props.layout.className == "Lead" ?
          { ...props.layout.jql.query.get, fields: props.layout.jql.query.get.fields.filter(f => f !== "borrowerEmails" && f !== "borrowerPhones" && !(f as any).duplicates) } :
          props.layout.jql.query.get
      }//todo remove this after merge with Armen changes
      skip={!id}
      key={id}
      operationName={`${props.layout.className}PageNode`}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-only"
      variables={{ id }}
    >
      {({ data, previousData }) => {
        const $object = data?.[ props.layout.jql.query.get.operation ] || previousData?.[ props.layout.jql.query.get.operation ];
        return !!$object && (
          <SourceProvider source={{ $object, $viewer, $settings }}>
            <JsonPage
              {...rest}
              page={props.layout}
              stats={stats}
            />
            {!!props.layout.jql.subscription &&
              <JqlSubscription
                skip={!id}
                onData={onData}
                subscription={props.layout.jql.subscription}
                operationName={`${props.layout.className}PageSubscription`}
                variables={{
                  events: [SubscriptionEvent.UPDATE],
                  where: {
                    id: { equalTo: id }
                  }
                }}
              />
            }
          </SourceProvider>
        );
      }}
    </JqlQuery>
  );
});
