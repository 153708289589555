import { useField }             from "@relcu/final-form";
import { Field }                from "@relcu/final-form";
import { createCalculation }    from "@relcu/form";
import { MultiTextField }       from "@relcu/ui";
import { isValidDate }          from "@relcu/ui";
import { DateField }            from "@relcu/ui";
import { ChoiceField }          from "@relcu/ui";
import { TextField }            from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { BackDropLoader }       from "@relcu/ui";
import { transformNameToLabel } from "@relcu/ui";
import { DateTime }             from "luxon";
import React                    from "react";
import { useMemo }              from "react";
import { ButtonVariants }       from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { ModalFooter }          from "@relcu/ui";
import { Modal }                from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { schemaVar }            from "../../../../reactiveVars";
import { getZoneNameMapping }   from "../../../../utils/timezone";
import { usTimeZoneNames }      from "../../../../utils/timezone";
import { toZoneName }           from "../../../../utils/timezone";
import { toTimezoneDate }       from "../../../../utils/timezone";
import { PointerField }         from "../../Field/PointerField";
import { ClassForm }            from "../../Form/ClassForm";
import { SchemaDialogProps }    from "../SchemaDialog";
import { useSchemaDialog }      from "../SchemaDialog";

export const TaskDialog = React.memo<SchemaDialogProps>(function TaskDialog(props) {
    const {
      initialValues,
      user,
      sections,
      jql,
      formProps,
      action,
      className,
      afterSubmit,
      loading,
      title,
      ...modal
    } = useSchemaDialog(props);
    const schemas = schemaVar();
    const isReferralEnabled = !!schemas.ReferralPartner;
    const isReferral = props.record?.subject?.__typename == "ReferralPartner";
    const isLead = props.record?.subject?.__typename == "Lead";
    const initial = useMemo(() => {
      if (props.record?.subject?.timezone && initialValues.subject) {
        return {
          ...initialValues,
          subject: {
            ...initialValues?.subject,
            timezone: props.record?.subject?.timezone
          }
        };
      }
      return initialValues;
    }, [initialValues]);
    const userPointerFilter = useMemo(() => {
      switch (user.role) {
        case "loan_officer":
          return {
            "id": {
              "equalTo": user.id
            }
          };
        case "team_manager":
          return {
            "team": {
              "equalTo": user.team
            }
          };
        default:
          return {};
      }
    }, [user.role]);
    const zoneNameMapping = getZoneNameMapping();
    return (
      <Modal
        {...modal}
        open={props.open}
        onClose={props.onClose}
        title={`${action === "save" ? "Edit" : "Create"} ${title ? String(title)?.toLowerCase() : transformNameToLabel(className)?.toLowerCase()}`}
        closeIcon={false}
        disableBackdropClick={false}
      >
        <ClassForm
          group={false}
          decorators={[decorators]}
          className={className}
          jql={jql}
          keepDirtyOnReinitialize={true}
          initialValues={initial}
          subscription={{ submitting: true }}
          sections={sections}
          user={user}
          beforeSubmit={(values) => {
            delete values.timezone;
          }}
          afterSubmit={afterSubmit}
          {...formProps}
        >
          {({ elements, submitting }) => (
            <>
              <ModalBody style={{ position: "relative" }} container direction={"column"} gap={"XS"} flexGrow={1}
                         className="base-dialog-container">
                <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                  <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                    <TextField
                      name={`title`}
                      halfSize
                      label={"Title"}
                      placeholder={"Enter title"}
                      required={true}
                      flex={1}
                    />
                  </Box>
                  <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                    <PointerField
                      flex={1}
                      required={true}
                      filters={userPointerFilter}
                      label={"Assigned user"}
                      placeholder="Assign to user"
                      name="assignedTo"
                      targetClass="User"
                    />
                  </Box>
                </Box>

                <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                  <Box container direction={"row"} gap={"XS"} flexBasis={"calc(50% - 16px)"}>
                    <PointerField
                      flex={1}
                      required={true}
                      filters={{ "duplicateOf": { "exists": false } }}
                      disabled={action == "save"}
                      label={isReferral ? "Referral partner" : ((isLead || !isReferralEnabled) ? "Lead" : "Subject")}
                      placeholder={isReferral ? "Assign to referral" : ((isLead || !isReferralEnabled) ? "Assign to lead" : "Assign to referral or lead")}
                      fields={isReferral ? [] : ["timezone"]}
                      name="subject"
                      targetClass={isReferral ? "ReferralPartner" : ((isLead || !isReferralEnabled) ? "Lead" : ["ReferralPartner", "Lead"])}
                    />
                  </Box>
                  <TimeZoneFiled/>
                  <Field name={"timezone"}>
                    {(props) => {
                      return <DateField
                        label={"Due date"}
                        required
                        flexBasis={"calc(50% - 174px)"}
                        name={"dueDate"}
                        format={(value, name) => {
                          const tz = zoneNameMapping[ props.input.value ]?.at(0) || Intl.DateTimeFormat().resolvedOptions().timeZone;
                          if (isValidDate(value)) {
                            const date = DateTime.fromJSDate(new Date(value));
                            const timezoneDate = date.setZone(tz);
                            return timezoneDate.toFormat("MM/dd/yyyy hh:mm a");
                          }
                        }}
                        parse={(value, name) => {
                          if (value) {
                            if (props.input.value) {
                              const tz = zoneNameMapping[ props.input.value ]?.at(0) || Intl.DateTimeFormat().resolvedOptions().timeZone;
                              return toTimezoneDate(value, tz);
                            }
                          }
                        }}
                        style={{
                          flexBasis: "calc(35% - ( 30px / 2 ))"
                        }}
                        placeholder={"Select due date"}
                        isTime
                      />;
                    }}
                  </Field>
                </Box>
                <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                  <ChoiceField
                    label={"Reminder"}
                    required
                    name={"remind"}
                    defaultValue={"10"}
                    placeholder={"Select due date"}
                    halfSize={true}
                    options={[
                      {
                        value: "0",
                        label: "On time"
                      },
                      {
                        value: "10",
                        label: "10 minutes before"
                      },
                      {
                        value: "15",
                        label: "15 minutes before"
                      },
                      {
                        value: "30",
                        label: "30 minutes before"
                      },
                      {
                        value: "60",
                        label: "1 hour before"
                      },
                      {
                        value: "1440",
                        label: "1 day before"
                      }
                    ]}
                  />

                </Box>
                <Box gap={"XS"} container direction="row" style={{ padding: "12px 8px" }}>
                  <MultiTextField placeholder={"Enter task description"} label={"Description"} name={"description"}
                                  flexBasis={"100%"}/>
                </Box>
                {loading && <BackDropLoader/>}
              </ModalBody>
              <ModalFooter>
                <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                        onClick={props.onClose}>CANCEL</Button>
                <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
              </ModalFooter>
            </>
          )}
        </ClassForm>
      </Modal>
    );
  }
);
const decorators = createCalculation(
  {
    field: "timezone",
    updates: (value, name, allValues: { dueDate }, prevValues) => {
      if (!allValues.dueDate) {
        allValues.dueDate = new Date()
        return allValues;
      }
      return {
        [ name ]: value
      };
    }
  },
);
const TimeZoneFiled = () => {
  const localTz = toZoneName(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const { input: { value: subject } } = useField("subject", { subscription: { value: true } });
  const timezoneOptions = useMemo(() => {
    const timezones = [...usTimeZoneNames];
    const scopeTz = subject.timezone && toZoneName(subject.timezone);
    if (scopeTz) {
      timezones.unshift(scopeTz);
    }
    timezones.unshift(localTz);
    const uniqueZones = [...new Set([...timezones])];
    return uniqueZones.map((name) => {
      const postfix = [];
      if (name == localTz) {
        postfix.push("My");
      }
      if (name == scopeTz) {
        postfix.push(subject.__typename);
      }
      return {
        value: name,
        label: `${name} ${postfix.length ? `(${postfix.join(",")})` : ""}`
      };
    });
  }, [subject.timezone]);
  return <ChoiceField
    label={"Timezone"}
    defaultValue={localTz}
    name={"timezone"}
    style={{ width: "140px" }}
    placeholder={"Timezone"}
    options={timezoneOptions}
  />;
};
