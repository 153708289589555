import React                          from "react";
import { FC }                         from "react";
import { useState }                   from "react";
import { useMemo }                    from "react";
import { useCallback }                from "react";
import { useRef }                     from "react";
import { ButtonVariants }             from "@relcu/ui";
import { ButtonSizes }                from "@relcu/ui";
import { Button }                     from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { ChipsVariants }              from "@relcu/ui";
import { SearchableMultiSelectProps } from "@relcu/ui";
import { AvatarSizes }                from "@relcu/ui";
import { MenuItem }                   from "@relcu/ui";
import { SearchableMultiSelect }      from "@relcu/ui";
import { Avatar, Chips, ChipsColors } from "@relcu/ui";
import { getSelectionSet }            from "../../../utils/graphQlUtils";
import { toFirstUpper }               from "../../../utils/helpers";
import { toFirstLower }               from "../../../utils/helpers";
import { pluralize }                  from "../../../utils/pluralize";
import { usePointerFilters }          from "../../Layout/Field/PointerField";
import { useJqlQuery }                from "../../Layout/Jql";

interface PointerValueFieldProps extends SearchableMultiSelectProps {
  targetClass: string | string[];
  thumbnail?: boolean;
}

export const PointerValueField: FC<Omit<PointerValueFieldProps, "searchText" | "options">> = React.memo(function PointerValueField(props) {
  const selectionSet = useMemo(() => getSelectionSet([]), []);
  const filters = usePointerFilters({});
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [q, setQ] = useState("");
  const { targetClass, name, ...otherProps } = props;
  const targetClasses = Array.isArray(targetClass) ? targetClass : [targetClass];
  const query = useMemo(() => {
    let query: any;
    let value = {
      ...filters
    };
    if (selectedFilter && selectedIds.length) {
      value[ "objectId" ] = {
        in: selectedIds
      };
    }
    let variables = {
      after: "",
      first: 5
    };

    if (q) {
      variables[ "search" ] = `${(q || "").trim()}`;
    }

    if (targetClasses.length > 1) {
      query = targetClasses.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            ...variables,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      variables[ "first" ] = 10;
      variables[ "where" ] = {
        type: `${targetClasses[ 0 ]}WhereInput`,
        value
      };
      query = {
        operation: pluralize(toFirstLower(targetClasses[ 0 ])),
        variables,
        fields: [
          {
            pageInfo: [
              "endCursor",
              "startCursor",
              "hasNextPage",
              "hasPreviousPage"
            ]
          },
          {
            edges: [
              {
                node: selectionSet
              }
            ]
          }
        ]
      };
    }
    return query;
  }, [q, targetClasses, selectedFilter, selectedIds]);
  const queryName = useMemo(() => {
    if (name?.includes(".")) {
      return name.split(".")[ 0 ];
    }

    return name;
  }, [name]);

  const { data = {}, loading, fetchMore } = useJqlQuery(query, {
    operationName: `${toFirstUpper(queryName)}PointerSelector`
  });

  const options = useMemo(() => {
      if (targetClasses.length > 1) {
        const items = [];
        Object.keys(data).forEach(t => {
          const label = t == "leadSources" ? "CAMPAIGN" : t.toUpperCase();
          const options = data[ t ].edges.map(({ node }) => node);
          if (options.length) {
            items.push({
              label,
              options: data[ t ].edges.map(({ node }) => node)
            });
          }
        });
        return items;
      }
      const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
      if (data[ operation ]) {
        return (
          Object(data[ operation ])[ "edges" ] || []
        ).map(({ node }) => node);
      }
      return [];
    },
    [data, targetClasses]
  );

  const selectRef: any = useRef();
  const handleDelete = (option) => {
    props.onChange([...props.value.filter(i => i.id !== option.id)]);
  };

  const handleSelect = useCallback((data) => {
    props.onChange(data.map(d => d?.objectId || d));
  }, [selectRef.current]);

  const pageInfo = useMemo(() => {
    const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, targetClasses]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        const className = pluralize(toFirstLower(targetClasses[ 0 ]));
        return {
          [ className ]: {
            ...prev[ className ],
            pageInfo: fetchMoreResult[ className ].pageInfo,
            edges: [...prev[ className ].edges, ...fetchMoreResult[ className ].edges]
          }
        };
      }
    });
  };

  return (
    <SearchableMultiSelect
      {...otherProps}
      selectedFilter={selectedFilter}
      onSelectedFilter={(selectedFilter) => {
        setSelectedFilter(selectedFilter);
        setSelectedIds(selectedFilter ? props.value : []);
      }}
      onLoadMoreHandler={
        pageInfo?.hasNextPage &&
        <Box container justify={"center"} style={{ position: "sticky", bottom: 0, background: "white" }}>
          <Button size={ButtonSizes.Small} variant={ButtonVariants.Ghost} onClick={handleLoadMore}>Load more</Button>
        </Box>
      }
      showSelectionActions
      ref={selectRef}
      options={options}
      onChange={handleSelect}
      showSelectedCount
      value={props.value}
      optionKey={"objectId"}
      searchText={q}
      onType={setQ}
      renderOption={(option) => (
        <MenuItem
          container
          thumbnail={props.thumbnail ?
            <Avatar icon={option.objectIcon} size={AvatarSizes.Small} text={option.objectName}/> : null}>
          {option.objectName}
        </MenuItem>
      )}
      renderSelectedItem={(selected) => (
        <Chips
          key={selected.objectId}
          color={ChipsColors.Grey}
          label={selected.objectName}
          variant={ChipsVariants.Fill}
          onDelete={!props.readOnly ? () => handleDelete(selected) : null}
          thumbnail={props.thumbnail ?
            <Avatar icon={selected.objectIcon} size={AvatarSizes.Small} text={selected.objectName}/> : null}
        />
      )}
    />
  );
});
