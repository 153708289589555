import { isValidDate } from "@relcu/ui";
import { Interval } from "luxon";
import { DateTime } from "luxon";
import { useMemo }  from "react";
export const usTimeZones = [
  "America/Adak",
  "America/Anchorage",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Los_Angeles",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Sitka",
  "America/Yakutat",
  "America/Shiprock",
  "Pacific/Honolulu"
];
export const getValidTimezones = (settings)=>{
  const {
    callableStartHour,
    callableEndHour,
    callableSaturdayEndHour,
    callableSaturdayStartHour,
    callableSundayEndHour,
    callableSundayStartHour
  } = settings;
  const now =  DateTime.local()
  return usTimeZones.filter((timezone) => {
    const time = now.setZone(timezone);
    let weekday = time.weekday;
    let callableHours = {
      startHour: callableStartHour,
      endHour: callableEndHour
    }
    if (weekday == 6) {
      callableHours = {
        startHour: callableSaturdayStartHour,
        endHour: callableSaturdayEndHour
      };
    } else if (weekday == 7) {
      callableHours = {
        startHour: callableSundayStartHour,
        endHour: callableSundayEndHour
      };
    }

    const [startHour, startMinute] = String(callableHours.startHour).split(":");
    const [endHour, endMinute] = String(callableHours.endHour).split(":");
    const start = DateTime.fromObject({
      day: time.day,
      month: time.month,
      year: time.year,
      hour: parseInt(startHour) ?? 8,
      minute: parseInt(startMinute) ?? 0,
      second: 0
    }, { zone: timezone });
    const end = DateTime.fromObject({
      day: time.day,
      month: time.month,
      year: time.year,
      hour: parseInt(endHour) ?? 21,
      minute: parseInt(endMinute) ?? 0,
      second: 0
    }, { zone: timezone });

    return Interval.fromDateTimes(start, end).contains(time);
  });
}
export function toZoneName(timezone:string){
  let dateTime = DateTime.local().setZone(timezone);
  return dateTime.toFormat("ZZZZ",{
    locale : "en-US"
  })
}
export function toTimezone(zoneName:string){

}
export function getZoneNameMapping(){
  const mapping = {}
  for(let tz of usTimeZones){
    const name = toZoneName(tz)
    mapping[name] ??=[]
    mapping[name].push(tz)
  }
  return mapping
}
export const toTimezoneDate = function (date: string, timezone = "US/Central"): Date {
  if (!(date && isValidDate(date))) {
    return;
  }
  const getOffset = (timeZone = "UTC", date = new Date()) => {
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    const totalMinutes = (tzDate.getTime() - utcDate.getTime()) / 6e4;
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return { hours, minutes };
  };

  const serverTimezoneOffset = getOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const configuredTimezoneOffset = getOffset(timezone);

  return new Date(DateTime.fromJSDate(new Date(date)).plus({
    hour: Number(serverTimezoneOffset.hours || 0),
    minute: Number(serverTimezoneOffset.minutes || 0)
  }).minus({
    hour: Number(configuredTimezoneOffset.hours || 0),
    minute: Number(configuredTimezoneOffset.minutes || 0)
  }).toISO());
};
export const usTimeZoneNames = ['HAST', 'AKST', 'MST', 'CST', 'EST', 'PST', 'HST']
