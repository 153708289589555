import { TypePolicy } from "@apollo/client";

export const LoanEstimateOffer: TypePolicy = {
  fields: {
    isTitleFeeEditable: {
      read(e) {
        return e ?? null;
      }
    },
    isValid: {
      read(e) {
        return e ?? null;
      }
    },
    isDirty: {
      read(e) {
        return e ?? null;
      }
    },
    propertyAnnualInsuranceUnit: {
      read(e) {
        return e ?? "currency";
      }
    },
    propertyAnnualTaxUnit: {
      read(e) {
        return e ?? "currency";
      }
    }
  }
};
