import React                     from "react";
import { FC }                    from "react";
import { useContext }            from "react";
import { MultiInput }            from "@relcu/ui";
import { MultiSelect }           from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { Input }                 from "@relcu/ui";
import { Select }                from "@relcu/ui";
import { getField }              from "../../../../../../utils/schemaUtils";
import { StatusField }           from "../../../../../QueryBuilder";
import { SwitchDatePickerField } from "../../../../../QueryBuilder";
import { NumberField }           from "../../../../../QueryBuilder";
import { PointerValueField }     from "../../../../../QueryBuilder";
import { TagsField }             from "../../../../../QueryBuilder/Fields/TagsField";
import { Tag }                   from "../../../../../Tag";
import { FilterContext }         from "./Filter";

export const FilterValueEditor: FC<any> = React.memo(function FilterValueEditor(props) {
  const { schemaName } = useContext(FilterContext);
  const { value, handleOnChange, operator, field, fieldData } = props;
  if (["notNull", "is null", "exists", "notExists"].includes(operator)) {
    return <Box flex={"0 1 30%"}/>;/*todo remove width*/
  }
  const schema = getField(schemaName, fieldData.schemaName || field);
  const type = field !== "tags" ? schema?.type : "Tag";
  switch (type) {
    case "Pointer":
      return <PointerValueField
        flex={"0 1 32%"}
        name={props.field}
        value={value || []}
        targetClass={schema.targetClass}
        onChange={handleOnChange} thumbnail={true}/>;
    case "Number":
    case "Percent":
      return <NumberField flex={"0 1 32%"} value={isNaN(value) ? null : value}
                          onChange={handleOnChange}/>;
    case "Boolean": {
      return <Select
        value={value}
        options={[
          {
            value: true,
            label: "True"
          },
          {
            value: false,
            label: "False"
          }
        ]}
        direction={"column"}
        style={{ padding: "0 8px" }}
        flex={"0 1 32%"}
        placeholder={"Select value"}
        onChange={(selected) => handleOnChange(selected.value)}
      />;
    }
    case "MultiChoice":
    case "Choice": {
      return <MultiSelect
        showSelectedCount
        showSelectionActions
        getOptionValueByKey
        value={value || []}
        options={schema.options}
        direction={"column"}
        style={{ padding: "0 8px" }}
        flex={"0 1 32%"}
        placeholder={"Select value"}
        onChange={handleOnChange}
      />;
    }
    case "Status": {
      return <StatusField field={field} key={field} operator={operator} value={value} schema={schema}
                          onChange={handleOnChange}/>;
    }
    case "Tag":
      return <TagsField targetClass={schemaName} value={props.value || []} onChange={props.handleOnChange}/>;
    case "Date":
      return <SwitchDatePickerField flex={"0 1 32%"}
                                    value={props.value}
                                    type={[">","<","greaterThan","lessThan"].includes(operator) ? "datetime" : (["during", "notDuring"].includes(operator) ? "calendar" : "date" )}
                                    onChange={(date) => props.handleOnChange(date)}/>;
    default:
      if (operator == "in" || operator == "notIn") {
        const value = typeof props.value == "string" ? props.value ? [props.value] : [] : props.value;
        return <MultiInput flex={"0 1 32%"} value={value} placeholder={"Type and press Enter"}
                           onChange={props.handleOnChange}/>;
      }
      return <Input flex={"0 1 32%"} value={props.value} placeholder={"Type value"} onChange={props.handleOnChange}/>;
  }
});
