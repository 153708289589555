import React                                    from "react";
import { FC }                                   from "react";
import { useContext }                           from "react";
import { useField }                             from "@relcu/final-form";
import { FieldCell }                            from "@relcu/rc";
import { FormWhere }                            from "@relcu/ui";
import { useSource }                            from "@relcu/ui";
import { Proposal }                             from "../../Proposal";
import { WHEN_LTV_MAX_PERCENT_IS_GREATER_THEN } from "../offer.conditions";

export const LtvCell: FC<any> = React.memo(function LtvCell(props) {
  const { $settings: { pricing: settings } } = useSource();
  const context = useContext(Proposal.Context);
  const purpose = context.loanPurpose === "purchase" ? "purchase" : "refinance";
  const { input: productTypeInput } = useField("productType");
  const { input: propertyValueInput } = useField("propertyValue");
  const minPercent = settings[ productTypeInput.value ]?.[ purpose ]?.minDownPaymentPercent;

  return <FormWhere
    conditions={WHEN_LTV_MAX_PERCENT_IS_GREATER_THEN}
    source={{
      $minPercent: minPercent,
      $propertyValuePercent: propertyValueInput.value * minPercent / 100
    }}>
    {
      ({ apply }) => (<FieldCell.Number
          status={apply ? "warning" : null}
          name={"ltv"}
          precision={3}
          required
          helperText={apply ? `Must be maximum ${100 - minPercent}%` : "Value can not be equal to zero or empty"}
          icon={"rc_percent"}/>
      )
    }
  </FormWhere>;
});
