import { useCallback }               from "react";
import { useMutation }               from "@apollo/client";
import { useAlert }                  from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import { UPDATE_SETTINGS }           from "../../../../graph/operations.graphql";
import { toNodeId }                  from "../../../../utils/helpers";

export function useEmailSignature() {
  const { $settings, $viewer } = useSource();
  const [update] = useMutation(UPDATE_SETTINGS);
  const { error, success } = useAlert();
  const addSignature = useCallback((fields: any) => {
    fields.push({ name: "", signature: "", enabledRelcu: true, enabledMicrosoftGmail: false });
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      await update({
        variables: {
          id: toNodeId({ className: "Settings", objectId: $settings.objectId }),
          fields: { emailSignature: data.emailSignature }
        }
      });
      success("Changes saved successfully.");
    } catch (e) {
      console.error(e);
      error(e?.message);
    }
  }, [$settings]);

  const handleDelete = useCallback(async () => {
    try {
      await update({
        variables: {
          id: toNodeId({ className: "Settings", objectId: $settings.objectId }),
          fields: { emailSignature: [] }
        }
      });
    } catch (e) {
      console.error(e);
      error(e?.message);
    }
  }, [$settings]);

  return {
    user: $viewer,
    addSignature,
    handleSubmit,
    handleDelete
  };
}
